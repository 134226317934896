.clock_out_form_container {
  position: relative;
  bottom: 98.5%;
  width: 100%;
  max-width: 100%;
  z-index: 99;
  min-width: 52vw;
  height: 75vh;

  .clock_out_modal {
    position: absolute;
    top: 0%;
    z-index: 99;
    height: 100%;
    width: 50vw;
    font-size: 0.8rem;
    background-color: #FFFFFF;
    border-radius: 0.313rem;
    padding: 0 2rem 0.5rem 2rem;
    overflow-y: auto;
    overflow-x: hidden;

    .checkOutHeader {
      font-size: 1.5rem;
      font-weight: 700;
    }

    hr {
      height: 0.12rem;
      background-color: $clockOutFormBorder;
      border: none;
    }

    .checkOut-section-first {
      padding: 1rem 2rem;
      background-color: #f5f5f5;
      margin: 5px 0px;
      color: #616161;

      .use_report_container {
        @include flexRow;

        .use_report {
          font-size: 1.2rem;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .precise {
        background-color: #d6e2f8;
        color: #698593;
        padding: 0.25rem 1rem 0.5rem 1rem;
        margin-top: 1.2rem;
        font-size: 1rem;
        font-weight: 600;
        height: 1.1rem;
        width: fit-content;
      }

      .not_ready {
        color: $clockOutFormColor;
        font-size: 1.2rem;
      }

      .support_btn {
        color: $whiteColor;
        background-color: $blackColor;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0.75rem 1rem;
        border: none;
      }
    }

    .checkOut-section {
      padding: 1rem;
      margin: 5px 0px;

      .accomplished {
        font-size: 1.2rem;
        font-weight: 500;
      }

      .feeling_container {
        @include flexRow;
        justify-content: space-between;
        margin-bottom: 1rem;

        div {
          label {
            font-size: 1.2rem;
            font-weight: 600;
            margin-left: 0.5rem;
            position: relative;
            bottom: 0.3rem;
            color: #757575;
          }
        }
      }

      .note_input {
        padding: 0.5rem 0;
        width: 60%;
        border: none;
        outline: none;
        border-bottom: 2px solid $clockOutFormBorder;

        &::-webkit-input-placeholder {
          font-style: italic;
        }
      }

      .please_note {
        font-size: 1rem;
        color: $clockOutFormColor;
        font-weight: 500;
        font-style: italic;

        span {
          color: #616161;
          font-weight: 600;
          font-style: normal;
          background-color: #deeaff;
          padding: 0 0.75rem;
          margin-right: 0.25rem;
        }
      }

      .sprint_data {
        @include flexRow;
        justify-content: space-between;
        color: #616161;
        font-size: 1rem;
        font-weight: 600;

        .sprint_name {
          margin-top: 0.5rem;
        }

        .client_name {
          background-color: #deeaff;
          padding: 0.25rem 1rem 0 1rem;
          border-radius: 0.5rem;
        }

        .time {
          background-color: #eeeeee;
          padding: 0.25rem 1rem 0 1rem;
          border-radius: 0.5rem;
        }
      }

      .sprint_feature {
        background-color: #f5f5f5;
        padding: 2rem;
        border-radius: 0.75rem;
        margin-left: -1rem;
        color: #616161;
        font-size: 1rem;
        font-weight: 600;
      }

      .anything_else_input {
        padding: 0.5rem 0;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid $clockOutFormBorder;

        &::-webkit-input-placeholder {
          font-size: 1.5rem;
          font-style: italic;
        }
      }

      .time_worked_header {
        @include flexRow;
        font-size: 1.2rem;
        font-weight: 600;

        p {
          color: #616161;
        }

        span {
          background-color: #feedd0;
          color: #d46841;
          padding: 0.25rem 1rem 0.5rem 1rem;
          // border-radius: 0.5rem;
          margin-top: 1.2rem;
          margin-left: 0.5rem;
          font-size: 1rem;
          height: 1.1rem;
          width: -moz-fit-content;
          width: fit-content;
        }
      }

      .time_worked_today {
        b {
          font-size: 1.5rem;
          font-weight: 400;
        }
      }

      .today_feeling_header {
        color: #616161;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .today_feeling_container {
        div {
          label {
            font-size: 1.2rem;
            font-weight: 600;
            margin-left: 0.5rem;
            position: relative;
            bottom: 0.3rem;
            color: #757575;
          }
        }
      }
    }

    .checkOut_btns {
      @include flexRow;
      justify-content: center;

      button {
        color: $whiteColor;
        border: none;
        font-size: 1.5rem;
        font-weight: 600;
        width: 40%;
        padding: 0.5rem 1rem;

        &:first-child {
          margin-right: 0.5rem;
          background-color: $clockOutFormColor;
          cursor: pointer;
        }

        &:nth-child(2) {
          margin-left: 0.5rem;
          background-color: $blackColor;
          cursor: pointer;
        }
      }
    }
  }

  .support {
    background-color: $blackColor;
    border-radius: 5px;
    color: $whiteColor;
    padding: 4px;
    border: none;
  }

  .welcome {
    font-weight: bold;
  }

  .active {
    background-color: rgb(238, 223, 223);
  }

  .homescreen {
    color: #a8a8a8;
  }
}

@media #{$media-desktop-plus2} {
  .clock_out_form_container {
    .clock_out_modal {
      .checkOut-section {
        .note_input {
          &::-webkit-input-placeholder {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .clock_out_form_container {
    bottom: 99%;
    height: 88vh;

    .clock_out_modal {
      width: 88%;
    }
  }
}

@media screen and (max-width: 1148px) {
  .clock_out_form_container {
    .clock_out_modal {
      .checkOut-section-first {
        .use_report_container {
          @include flexColumn;

          .use_report {
            margin-bottom: 0;
            font-size: 1.2rem;
          }

          .precise {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media screen and (width: 1024px) {
  .clock_out_form_container {
    bottom: 97%;
    height: 74vh;

    .clock_out_modal {
      width: 92.5%;
    }
  }
}

@media #{$media-nine-twelve}, #{$media-eight-twenty} {
  .clock_out_form_container {
    bottom: 95.5%;
    height: 76vh;

    .clock_out_modal {
      width: 90%;

      .checkOut-section {
        .time_worked_header {
          @include flexColumn;

          span {
            margin-top: 0;
            margin-left: 0;
          }
        }
      }

      .checkOut_btns {
        button {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (min-width: 593px) and (max-width: 768px) {
  .clock_out_form_container {
    .clock_out_modal {
      width: 94%;
    }
  }
}

@media #{$media-five-fourty} {
  .clock_out_form_container {
    bottom: 91%;
    height: 87vh;

    .clock_out_modal {
      width: 92.5%;
    }
  }
}

@media screen and (max-width: 480px) {
  .clock_out_form_container {
    bottom: 94%;
    height: 91vh;

    .clock_out_modal {
      width: 90%;
    }
  }
}

@media screen and (width: 428px) {
  .clock_out_form_container {
    bottom: 94%;
    height: 91vh;

    .clock_out_modal {
      width: 91%;
    }
  }
}

@media screen and (width: 414px) {
  .clock_out_form_container {
    bottom: 94.5%;
    height: 91vh;

    .clock_out_modal {
      width: 90%;
    }
  }
}

@media screen and (width: 412px) {
  .clock_out_form_container {
    bottom: 94.5%;
    height: 92vh;

    .clock_out_modal {
      width: 90%;
    }
  }
}

@media screen and (height: 1366px) {
  .clock_out_form_container {
    bottom: 82%;
    height: 75vh;
  }
}

@media screen and (height: 812px) {
  .clock_out_form_container {
    bottom: 87.5% !important;
  }
}

@media screen and (height: 740px) {
  .clock_out_form_container {
    bottom: 60% !important;
    height: 88vh !important;
  }
}

@media screen and (height: 736px) {
  .clock_out_form_container {
    bottom: 98%;
    height: 89vh;
  }
}

@media screen and (width: 390px) {
  .clock_out_form_container {
    bottom: 96%;
    height: 90vh;

    .clock_out_modal {
      width: 90%;
    }
  }
}

@media screen and (height: 667px) {
  .clock_out_form_container {
    bottom: 91% !important;
    height: 86vh;
  }
}

@media screen and (min-width: 361px) and (max-width: 389px) {
  .clock_out_form_container {
    bottom: 98.7% !important;
    height: 84vh;
  }
}

@media screen and (height: 700px) {
  .clock_out_form_container {
    bottom: 97.9%;
    height: 85vh;
  }
}

@media #{$media-seven-sixty-eight} {
  .clock_out_form_container {
    bottom: 93%;
    height: 90vh;
  }
}

@media #{$media-three-seventy-five} {
  .clock_out_form_container {
    bottom: 60% !important;
    height: 88vh !important;
  }
}

@media screen and (width: 360px) {
  .clock_out_form_container {
    bottom: 56.6%;
    height: 88vh;
  }
}

@media screen and (width: 393px) {
  .clock_out_form_container {
    bottom: 96% !important;
    height: 91vh !important;
  }
}


@media #{$media-two-eighty} {
  .clock_out_form_container {
    height: 85vh;
    bottom: 88% !important;

    .clock_out_modal {
      width: 85%;

      .checkOut_btns {
        button {
          font-size: 0.9rem;
        }
      }
    }
  }
}
