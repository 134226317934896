.review_modal_form_container {
  position: relative;
  bottom: 96%;
  width: 100%;
  height: 74vh;
  z-index: 99;

  .review_modal {
    position: absolute;
    z-index: 99;
    min-width: 85%;
    max-width: 93%;
    width: 92.5%;
    height: 98.6%;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.313rem;
    padding: 2rem;
    box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      font-size: 1.1rem;
      //text-align: center;
      font-weight: 500;

      &:first-child {
        //color: #4960e1;
        color: #000000;
        font-weight: 700;
        font-size: 1.5rem;
        //   margin: 0%;
        //  margin-top: -0.5rem;
      }

      &:nth-child(2),
      &:nth-child(3) {
        color: #000000;
      }
    }
    .review_modal_item {
      .back_btn {
        align-self: left;
        width: fit-content;
        @include flexRow;
        gap: 1rem;
        margin-bottom: 2rem;
        color: #000000;
        //margin-top: 2rem;
        p {
          margin: 0%;
          font-weight: 600;
          font-size: 1.3rem;
          margin-top: -0.25rem;
        }
      }
      .dev {
        display: flex;
        justify-content: space-between;

        .dev-details {
          display: flex;
          gap: 1rem;
          max-width: fit-content;

          .dev_avatar {
            height: 8rem;
            width: 8rem;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .dev-data {
            max-width: fit-content;
            padding: 0.5rem;

            .location {
              @include flexRow;
              margin-left: -0.3rem;
            }

            .dev-name {
              color: #000000;
              font-weight: 800;
              font-size: 1.4rem;
            }

            p {
              margin: 0%;
              font-size: 1.3rem;
            }
          }
        }

        .dev-days-active {
          //max-width: 50px;
          padding: 0 10px;
          margin-left: auto;
          text-align: center;
          background-color: $greyColor;
          //margin-right: 10px;
          //max-width: fit-content;
          width: 25%;

          .activedays {
            // margin:0 0 auto 0;
            text-align: right;
            font-size: 2.5rem;
            margin: 0.8rem 0 1rem 0;
          }

          .vector {
            @include flexRow;
            justify-content: space-between;

            p {
              margin: 0%;
              font-size: 1.2rem;
            }
          }
        }
      }
      .activity-graph {
        @include flexColumn;

        .activity {
          align-self: left;
          width: fit-content;
          margin-top: 3rem;
          @include flexRow;
          gap: 1rem;
          margin-bottom: 0.5rem;

          p {
            color: #000000;
            font-weight: 700;
            font-size: 1.5rem;
            margin: 0%;
            margin-top: -0.5rem;
          }
        }
        .graph {
          height: 100px;
          width: 100%;

          p {
            margin: 0%;
            margin-top: 0.5rem;
            color: #47505a;
          }
          .dev-lastlogin {
            text-align: left;
            border-top: 1px solid #47505a;
            margin-top: 0;
            font-size: 1.3rem;
          }
        }
        .no-data {
          height: 100px;
          width: 100%;
          text-align: center;
          background-color: #e0e0e0;

          p {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            color: #000000;
            font-size: 1.1rem;
            margin: auto;
            align-self: center;
            align-content: center;
            /* text-align: center; */
            margin-top: 4rem;
          }
        }
      }
      .dev-timesheet {
        display: flex;
        justify-content: space-between;
        margin-top: 5rem;
        border-bottom: 1px solid $checkInBorder;
        padding-bottom: 1rem;

        .timesheets {
          font-weight: 600;
          font-size: 1.3rem;
        }
        .worked {
          color: $lightBlueColor;
          font-size: 1.1rem;
        }
      }

      .dev-presence-data {
        @include flexRow;
        justify-content: space-between;
        border-bottom: 1px solid $checkInBorder;

        .prev-date-container,
        .next-date-container {
          @include flexRow;
          align-items: center;
          gap: 0.5rem;
          font-size: 1.1rem;

          .header {
            //width: 33%;
            //padding: 1rem;
            width: fit-content;
          }

          .prev_date,
          .next_date {
            //margin: 1rem;
            color: #a5a6a8;
            // font-size: 1.1rem;
          }
        }
        .current_date {
          margin: 1rem;
          color: #a5a6a8;
          align-self: center;
          font-size: 1.1rem;

          .header {
            width: 33%;
            padding: 1rem;
          }
        }
        .active_date {
          color: $oratioBlue2;
        }
      }

      .checkin-content {
        margin-top: 3rem;

        .checkin-content-header {
          @include flexRow;
          justify-content: space-between;
          margin-right: 1.5rem;
          margin-left: 1rem;
          font-size: 1.1rem;
          font-weight: 500;
        }

        .checkin-content-data {
          @include flexRow;
          justify-content: space-between;
          margin-bottom: 1rem;
          margin-left: 1rem;

          .child {
            width: 33%;
            padding: 1rem 0;
            text-align: right;
          }

          .edit_btn {
            cursor: pointer;
          }

          .font-green {
            //color: $oratioBlue2;
            font-size: 1.2rem;
            font-weight: 700;
          }

          .uppercase {
            text-transform: uppercase;
            text-align: left;
          }

          .time {
            font-weight: bold;
            text-align: center;
            font-size: 1.1rem;
          }
        }

        .empty_timesheet {
          @include flexRow;
          justify-content: space-between;

          p {
            font-size: 1.2rem;
            font-weight: 700;

            &:first-child,
            &:nth-child(2) {
              color: #000000;
            }

            &:nth-child(3) {
              color: $oratioBlue2;
            }
          }
        }

        .checkin-content-report {
          @include flexColumn;
          width: 100%;
          margin-bottom: 0.5rem;

          .checkin-button {
            border: none;
            padding: 1.4rem 2rem;
            text-align: center;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            //border-radius: 0.8rem;
            width: 100%;
            cursor: pointer;

            span {
              color: #000000;
            }

            .first_child {
              width: 25%;
              text-align: left;

              b {
                font-weight: 700;
              }
            }

            .border {
              color: #ffffff;
            }
          }

          .week {
            background: rgba(0, 0, 0, 0.1);
            margin-bottom: 0.5rem;
            font-size: 1.1rem;

            span {
              &:first-child b {
                font-weight: 400;
              }
            }
          }

          .month {
            background: rgba(0, 0, 0, 0.5);
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
            color: #ffffff;
          }

          .all-time {
            background-color: #9fadff;
            margin-bottom: 0.5rem;
          }
        }

        .performance-feedback {
          font-weight: 600;
          font-size: 16px;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }

        .performance-feedback-feature-text {
          display: flex;
          gap: 0.5rem;
          background-color: #fadcd3;
          align-items: center;
          // margin-bottom: 2rem;
        }

        .feedback-slider {
          margin-top: 3rem;
        }

        .performance-satisfied {
          font-weight: 600;
          font-size: 16px;
          margin-top: 2rem;
          margin-bottom: 1rem;
          text-align: center;
        }

        .medium-feedback-text {
          font-size: 10px;
          font-weight: 200;
          margin-bottom: 2rem;
        }

        .submit {
          width: 100%;
          //height: 200px;
          border: 2px solid black;
          background: #ffffff;
          display: block;
          padding: 5px 5px;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
        }

        .past-feedback {
          width: 100%;

          .feedback-accordion {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            //margin: 10px 0;
            justify-content: space-between;

            .past-feedback-header {
              font-weight: 600;
              font-size: 16px;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }
          }

          .feedback {
            width: 100%;
            .feedback-data {
              display: flex;
              align-items: center;
              justify-content: space-between;
              //margin-left: 2rem;
              margin-right: 2rem;

              p {
                font-size: 10px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .review_modal_form_container {
    bottom: 94%;
    height: 91vh;

    .review_modal {
      width: 90%;
    }
  }
}

@media screen and (width: 1280px) {
  .review_modal_form_container {
    bottom: 90%;
    height: 76vh;

    .review_modal {
      width: 92%;
    }
  }
}

@media screen and (width: 1024px) {
  .review_modal_form_container {
    bottom: 102%;
    height: 73vh;

    .review_modal {
      width: 89.5%;
    }
  }
}

@media #{$media-nine-twelve} {
  .review_modal_form_container {
    bottom: 95%;
    height: 78vh;

    .review_modal {
      width: 88.5%;
    }
  }
}

@media #{$media-eight-twenty} {
  .review_modal_form_container {
    bottom: 94.5%;
    height: 77vh;

    .review_modal {
      width: 88%;
    }
  }
}

@media #{$media-seven-sixty-eight} {
  .review_modal_form_container {
    bottom: 92% !important;
    height: 91.5vh;

    .review_modal {
      max-width: 94%;
      width: 97% !important;
    }
  }
}

@media #{$media-five-fourty} {
  .review_modal_form_container {
    bottom: 89.5% !important;
    height: 85vh !important;

    .review_modal {
      width: 94% !important;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 800px) {
  .review_modal_form_container {
    bottom: 100%;
    height: 91vh;

    .review_modal {
      width: 91%;
    }
  }
}

@media only screen and (height: 932px) {
  .review_modal_form_container {
    bottom: 89%;
    height: 90vh;
    .review_modal {
      width: 90%;
    }
  }
}

@media only screen and (min-width: 380px) and (max-width: 803px) {
  .review_modal_form_container {
    bottom: 93.5%;
    height: 89vh;
    .review_modal {
      width: 90%;
    }
  }
}

@media only screen and (min-width: 319px) and (max-width: 379px) {
  .review_modal_form_container {
    bottom: 98.7%;
    height: 85vh;
    .review_modal {
      width: 90%;
    }
  }
}

@media screen and (height: 700px) {
  .review_modal_form_container {
    bottom: 98%;
    height: 87vh;
  }
}

@media screen and (height: 667px) {
  .review_modal_form_container {
    bottom: 99% !important;
    height: 90vh;
    .review_modal {
      width: 90%;
    }
  }
}

@media #{$media-three-ninety-three} {
  .review_modal_form_container {
    bottom: 95.5%;
    height: 91vh;

    .review_modal {
      width: 89.5%;
    }
  }
}

@media #{$media-three-sixty} {
  .review_modal_form_container {
    bottom: 57%;
    height: 88vh;

    .review_modal {
      width: 89.5% !important;
    }
  }
}

@media #{$media-three-ninety} {
  .review_modal_form_container {
    bottom: 95% !important;
    height: 90vh;
    background-color: grey;
    border: 2px sold red;

    .review_modal {
      width: 89.5% !important;
    }
  }
}

@media #{$media-two-eighty} {
  .review_modal_form_container {
    bottom: 84.5%;
    height: 84vh;

    .review_modal {
      width: 80%;
    }
  }
}

@media screen and (height: 1366px) {
  .review_modal_form_container {
    bottom: 84%;
    height: 77.5vh;

    .review_modal {
      width: 89.5%;
    }
  }
}

@media screen and (height: 812px) {
  .review_modal_form_container {
    bottom: 90%;
    height: 90vh;

    .review_modal {
      width: 89%;
    }
  }
}

@media screen and (height: 896px) {
  .review_modal_form_container {
    bottom: 94%;
    height: 90vh;
  }
}
@media screen and (height: 740px) {
  .review_modal_form_container {
    bottom: 57%;
    height: 87vh;
    .review_modal {
      width: 88%;
    }
  }
}

@media screen and (height: 736px) {
  .review_modal_form_container {
    bottom: 98%;
    height: 89vh;

    .review_modal {
      width: 90%;
    }
  }
}

@media screen and (width: 390px) {
  .review_modal_form_container {
    bottom: 95% !important;
    height: 89vh !important;
  }
}

@media screen and (height: 844px) {
  .review_modal_form_container {
    bottom: 94.5%;
    height: 88vh;
    .review_modal {
      width: 90%;
    }
  }
}

@media screen and (height: 851px) {
  .review_modal_form_container {
    bottom: 94% !important;
    height: 91vh !important;
  }
}

// @media #{$media-three-seventy-five} {
//   .review_modal_form_container {
//     bottom: 95%;
//     height: 88vh;

//     .review_modal {
//       width: 89%;
//     }
//   }
// }
