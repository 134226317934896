.tech_connections {
  width: auto;
  height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;

  .tech_card {
    display: flex;
    height: fit-content;
    border-bottom: 1px solid #c0c0c0;
    margin: 0 1.25rem;
  }

  .tech_avatar {
    @include flexRow;
    width: 8%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    margin-right: 2rem;

    .avatar_image_tech{
      height: 3.438rem;
      width: 3.438rem;
      border-radius: 50%;
    }
  }

  .tech_section {
    padding-top: 0.813rem;
    margin-left: -0.938rem;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tech_info {
      height: 30%;
      width: 100%;
    }
    
    
    .tech_info span {
      margin-bottom: -0.5rem;
      padding: 0%;
      align-items: center;
      display: flex;
      font-weight: 700;
      color: #6f6f6f;
    }

    .tech_info small {
      font-size: 0.625rem;
      font-weight: 500;
      color: #6f6f6f;
      overflow: hidden;
    }

    .tech_info p {
      margin-top: 0;
      padding: 0%;
      align-items: center;
      font-size: 0.80rem;
      font-weight: 400;
      color: $textColor;
    }
  }
}

// resizes the width of the scroll bar
.tech_connections::-webkit-scrollbar {
  width: 0.3rem;;
}

@media #{$media-desktop-plus} {
  .tech_connections {
    height: 80vh;
  }
}
