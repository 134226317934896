.message_section {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  // height: 100%;
  height: 70%;
  // border-bottom: 1px solid rgba(216, 216, 216, 0.384) ;
  border-left: 1px solid $darkGreyColor;

  .message_contents {
    padding-top: 0.938rem;
    width: 97%;
    height: auto;
    display: flex;
    padding-top: 0.625rem;
    padding-left: 0.938rem;
    padding-bottom: 0.625rem;
    // border-bottom: 2px dotted rgba(216, 216, 216, 0.384);

    .msg-user-avatar {
      display: flex;
      justify-content: center;
      height: 3rem;

      .msg-avatar_image {
        height: 3rem;
        width: 3rem;
        // border-radius: 50%;
      }
    }

    .mail_info_container {
      color: $darkGreyColor;
    }

    .msg_info_container {
      background-color: $whiteColor;
      margin-left: 0.25rem;
    }

    .msg_info_container,
    .mail_info_container {
      display: flex;
      flex-direction: column;
      width: 88%;

      .msg-info-section,
      .mail-info-section {
        width: 96%;
        height: fit-content;
        display: flex;
        font-size: smaller;
        padding: 0 0.625rem;
        border-radius: 0.313rem;
        flex-direction: column;

        p {
          padding-left: 0;
          padding-bottom: 0.313rem;
          margin: 0%;
          font-weight: 500;
          font-size: 10px;
        }
    
        img {
          transition: transform 0.3s;
    
          &:hover {
            transform: scale(1.5);
          }
        }

        .author_identity {
          @include flexRow;
          justify-content: space-between;

          .name_email_container {
            @include flexRow;
            
            p {
              color: $blackColor;
              font-weight: 500;
              font-size: 0.75rem;

            }
            
            span {
              font-size: 0.6rem !important;
              color: #6F6F6F;
              margin-left: 0.15rem;
            }
          }

          small {
            font-size: 0.6rem;
          }
        }

        .mail_contents {
          background-color: $mailBoxBgColor;
          // background-color: $orangeColor;
          padding: 0.5rem 1rem;

          .to_cc_bcc_container {
            margin-bottom: 0.5rem;
            font-size: 0.7rem;
            @include flexColumn;
  
            span {
              background-color: $whiteColor;
              color: $darkGreyColor;
              padding: 0.15rem 0.5rem;
              border-radius: 0.5rem;
              width: fit-content;
              margin-bottom: 0.25rem;
            }
          }
  
          .subject {
            margin-bottom: 0.5rem;
  
            b {
              color: #000000;
            }
          }
  
          .body {
            border-bottom: 1px solid #CFCFCF;
            margin-bottom: 0.5rem;

            p {
              color: $textColor;
            }
          }

          .attachment_container {
            p {
              font-size: 0.8rem;
              color: $darkGreyColor;
              font-weight: 700;
            }
            .attachments {
              @include flexRow;
              width: auto;
              height: 4.5rem;
              // height: auto;
              overflow-x: auto;
              overflow-y: hidden;
              padding-bottom: 0.25rem;
  
              &::-webkit-scrollbar {
                height: 2px;   /* height of horizontal scrollbar */
                width: 2px;    /* width of vertical scrollbar */
              }
  
              .pdf_container {
                .file_data {
                  width: 6rem;
                  height: 2.5rem;
                  background-color: $whiteColor;
                  @include flexRow;
                  padding: 0.5rem;
                  margin-right: 0.5rem;
                  border-radius: 0.5rem;
                  
                  img {
                    width: 2rem;
                    height: 2rem;
                  }
  
                  span {
                    font-size: 0.6rem;
                    font-weight: 600;
                    overflow-wrap: break-word;
                    color: $darkGreyColor;
                    width: 4rem;
                  }
                }
  
                .pdf_downloap_link {
                  text-align: center;
                }
              }
  
              img {
                width: 4rem;
                height: 4rem;
                margin-right: 0.25rem;
              }
  
              img {
                width: 4rem;
                height: 100%;
                margin-right: 0.25rem;
              }
            }
          }
        }

        .name_edit {
          display: flex;
          justify-content: space-between;

          .edit_icon {
            width: fit-content;
            height: fit-content;
            margin-right: auto;
            margin-left: 1rem;
            cursor: pointer;

            &:hover {
              transform: scale(1.5, 1.5);
              transition: 1s;
            }
          }

          .delete_msg_icon {
            width: fit-content;
            height: fit-content;
            margin-right: -0.5rem;
            margin-bottom: 0.25rem;
            cursor: pointer;

            &:hover {
              transform: scale(1.5, 1.5);
              transition: 1s;
            }
          }

          p {
            color: $msgMetaDataColor;
            font-weight: 600;
          }

          small {
            color: $msgMetaDataColor;
            font-weight: 500;
          }
        }

        .msg_section {
          display: flex;
          width: 90%;
          height: auto;
          overflow: hidden;
          color: $blackColor;
          font-size: 1.15rem;

          .edited {
            font-size: 0.75rem;
            font-weight: 300;
            line-height: 1.375rem;
            margin-left: 0.2rem;
            color: $darkGreyColor;
          }

          .read_receipts_container,
          .unread_receipts_conatainer {
            margin: auto 0 0 0.5rem;
          }
        }

        .author_msg_section {
          margin-top: -0.75rem;
          color: $blackColor;
          font-size: 1.15rem;
        }

        .file_container {
          @include flexRow;
          // gap: 1.5rem;
          overflow-x: auto;
          overflow-y: hidden;
          
          .file_content {
            @include flexColumn;

            .file_data {
              @include flexColumn;

              img {
                // height: 100%;
                // width: 100%;
                height: 8rem;
                width: 12rem;
                object-fit: contain;
              }
            }
          }
        }

        .image-message {
          height: 12.5rem;
          width: 12.5rem;
          margin-top: 0.625rem;
          object-fit: contain;
        }
      }

      .save_cancel {
        position: relative;
        bottom: 1.4rem;
        // width: 17.5rem;
        width: 100%;

        .edit_msg_input {
          border: 1px solid #DAD7D7;
          color: $blackColor;
          border-radius: 0.188rem;
          outline: transparent;
          width: 93%;
          padding: 0.75rem;
          font-size: 1rem;
        }

        div {
          display: flex;
          justify-content: flex-end;
          width: fit-content;
          margin: 1% 0% 1% auto;
        }

        .save_btn,
        .cancel_btn {
          border: none;
          // border-radius: 0.313rem;
          padding: 0.3rem 0.75rem;
          margin-right: 0.25rem;
          font-size: 1rem;
          font-weight: 700;
          cursor: pointer;
        }

        .save_btn {
          color: $whiteColor;
          background-color: $greenColor;
        }

        .cancel_btn {
          color: $whiteColor;
          background-color: $darkGreyColor;
          margin-right: 0.313rem;
        }
      }
    }
  }
}

.message_section::-webkit-scrollbar {
  width: 0.4rem;
}

@media screen and (min-width: 1041px) and (max-width: 1245px) {
  .message_section {
    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1%;
          }
        }
      }
    }

    .mail-info-section {
      .author_identity {
        small {
          width: 23%;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1040px) {
  .message_section {
    height: 58%;
    
    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 430px) and (max-width: 768px) {
  .message_section {
    height: 62%;
    
    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1%;
          }
        }
      }
    }
  }
}

.file_preview_container {
  @include flexRow;
  overflow-x: auto;
  overflow-y: hidden;
  width: fit-content;
  max-width: 100%;
  height: 3rem;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 
  0px 3px 1px -2px rgb(0 0 0 / 12%),
  0px 1px 5px 0px rgb(0 0 0 / 20%);

  img {
    width: 2rem;
    height: 100%;
    margin: 0 0.25rem
  }

  span {
    cursor: pointer;
    font-weight: 600;
    position: relative;
    bottom: 0.75rem;

    &:hover {
      color: rgb(240, 18, 18);
    }
  }
}

.message_btns,
.email_btns {
  @include flexRow;
  justify-content: space-between;
  position: relative;
  gap: 0.25rem;
  margin: 0 1.8%;

  .discussion_icons {
    @include flexRow;
    flex: 1;
    max-height: 2.7rem;
    width: 40%;

    .message_icon {
      width: 4rem;
    }

    .email_icon {
      margin-left: -0.15rem;
    }

    .text_container {
      position: absolute;
      left: 20%;
      transform: translate(-50%, calc(-100% - 10px)); // updated
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
  
      p {
        padding: 0.5rem 1rem;
        background-color: $darkGreyColor;
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        text-align: center;
        white-space: nowrap;
        margin: 0;
        pointer-events: all;
      }
    }
  }

  .email_send_btn,
  .msg_send_btn {

    .msg-bn1 {
      padding: 0.8em 3em;
      border: none;
      background-color: $blackColor;
      // border-radius: 0.313rem;
      font-size: 1rem;
      box-sizing: border-box;
      text-decoration: none;
      font-family: 'Manrope', sans-serif;
      font-weight: 600;
      color: $whiteColor;
      text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
      text-align: center;
      transition: all 0.2s;
      width: 100%;
      cursor: pointer;

      &:hover {
        color: rgb(221, 212, 212);
        background-color: $darkGreyColor;
        transform:scale(1.03, 1.03);
      }
    }
  }

  .email_send_btn {
    width: 70%;
  }

  .msg_send_btn {
    width: 95%;
  }
}

.message_btns {
  top: 0.3rem;
}

.email_btns {
  top: 0.9rem;
}

.invalid_email {
  position: absolute;
  right: 50%;
  left: 50%;
  top: 50%;
  background-color: $whiteColor;
  color: $darkGreyColor;
  min-width: 20rem;
  min-height: 4rem;
  padding: 1rem;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 
              0px 3px 1px -2px rgb(0 0 0 / 12%),
              0px 1px 5px 0px rgb(0 0 0 / 20%);
              z-index: 99;

  .close_err_popup {
    text-align: right;
    color: #770000;
    font-weight: 600;
    cursor: pointer;
  }
  
  div {
    width: 1.3rem;
    padding: 0.4rem 0.75rem;
    margin-left: auto;
    border: none;
    background-color: $oratioBlue2;
    border-radius: 0.313rem;
    font-size: 1rem;
    text-decoration: none;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: $whiteColor;
    text-shadow: 0 0.04rem 0.04rem rgb(255 255 255 / 25%);
    transition: all 0.2s;
    cursor: pointer;
  }
}

@media #{$media-desktop-plus} {
  .message_section {
    height: 71%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1.1%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 3.5%;

    .discussion_icons {
      max-height: 3rem;
      margin-top: 0.25rem;
    }

    .email_send_btn,
    .msg_send_btn {
      margin-right: 0.25rem;
  
      .msg-bn1 {
        width: 100%;
        padding: 1em 2.5em 1em 2.5em;
      }
    }
  }
}

@media only screen and (width: 1280px) {
  .email_btns {
    top: 6.5%;
  }
}

@media only screen and (min-width: 1024px) {
  .message_section {
    // height: 55%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 0%;
          }
        }
      }
    }
  }
}

@media only screen and (width: 1024px) {
  .message_section {
    height: 55%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 0%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 7% !important;
  }
}

@media #{$media-nine-twelve} {
  .message_section {
    height: 75%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 0%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 6% !important;
  }
}

@media #{$media-eight-twenty} {
  .message_section {
    height: 73%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 0%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 4.5% !important;
  }
}

@media only screen and (width: 768px) {
  .message_section {
    height: 73%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 4%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 5% !important;
    
    .email_send_btn {
      width: 85%;
    }
  }
}

@media only screen and (width: 540px) {
  .message_section {
    height: 66%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 3%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 3% !important;

    .email_send_btn {
      width: 80%;
    }
  }
}

@media only screen and (width: 414px) {
  .message_section {

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1.3% !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .message_section {
    height: 71%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1.5%;
          }
        }
      }
    }
  }
}

@media only screen and (width: 430px) {
  .message_btns,
  .email_btns {
    top: 4.5%;
  }
}

@media only screen and (width: 412px) {
  .message_section {
    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1.5%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 4.5%;
  }
}

@media only screen and (width: 428px) {
  .message_btns,
  .email_btns {
    top: 4.5%;
  }
}

@media #{$media-three-ninety-three} {
  .message_section {
    height: 71%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1%;
          }
        }
      }
    }
  }
  
  .message_btns,
  .email_btns {
    top: 3.5%;
  }
}

@media only screen and (max-width: 390px) {
  .message_section {
    height: 80%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1%;
          }
        }
      }
    }
  }
}

@media only screen and (width: 375px) {
  .message_section {
    height: 63%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1%;
          }
        }
      }
    }
  }
  
  .message_btns,
  .email_btns {
    top: 5.5%;
  }
}

@media #{$media-three-sixty} {
  .message_section {
    height: 69%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          div {
            margin-right: 1%;
          }
        }
      }
    }
  }
  
  .message_btns,
  .email_btns {
    top: 5%;
  }
}

@media #{$media-two-eighty} {
  .message_section {
    height: 63%;

    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1.5%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 5.5%;
  }
}

@media only screen and (height: 812px) {
  .message_section {
    height: 68%;
    
    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1.5%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 3.5%;
  }
}

@media only screen and (height: 740px) {
  .message_section {
    height: 66%;
    
    .message_contents {
      .msg_info_container {
        .save_cancel {
          width: 97%;

          div {
            margin-right: -1.5%;
          }
        }
      }
    }
  }

  .message_btns,
  .email_btns {
    top: 4.5%;
  }
}

@media only screen and (height: 736px) {
  .message_section {
    height: 66%;
  }
  
  .message_btns,
  .email_btns {
    top: 4%;
  }
}

@media only screen and (height: 1366px) {
  .message_section {
    height: 75%;
  }

  .message_btns,
  .email_btns {
    top: 6% !important;
  }
}
