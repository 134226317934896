.new_group_modal_container {
  position: absolute;
  width: 97.9%;
  height: 100%;
  z-index: 100000;
  background-color: $whiteColor;
  padding: 0 0.4rem;

  .new_group_header {
    @include flexRow;
    justify-content: space-between;
    font-weight: 400;
    margin-top: 0.5rem;
    
    .header {
      @include flexRow;
      gap: 0.3rem;
      margin-bottom: 0.5rem;

      p {
        color: $blackColor;
        margin: 0;
      }
    }

    .toggle_switch_container {
      @include flexRow;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      p {
        margin: 0;

        &:first-child {
          color: #8A8888;
        }

        &:nth-child(2) {
          color: $blackColor;
        }
      }
    
      .toggle_switch {
        position: relative;
        bottom: 0.1rem;
        display: inline-block;
        width: 3.7rem;
        height: 1.7rem;
        background-color: $lightGreyColor;
        border-radius: 1.7rem;
        cursor: pointer;

        .toggle_handle {
          position: absolute;
          top: 2px;
          // left: 2px;
          right: 2px; // use 'right' instead of 'left'
          width: 1.2rem;
          height: 1.2rem;
          background-color: $blackColor;
          border-radius: 50%;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
          transition: transform 0.2s ease;
        }
      }

      // .private {
      //   pointer-events: none;
      //   opacity: 0.4;
      // }
    
      .toggle_switch.on {
        background-color: $greenColor;

        .toggle_handle {
          transform: translateX(-20px);
        }
      }
    
      .toggle_switch.off {
        background-color: #D9D9D9;
      }
    }
  }

  .group_name_container,
  .group_description_container,
  .search_users_container {
    @include flexColumn;
    margin-bottom: 1rem;
    
    label {
      color: $blackColor;
      font-weight: 500;
    }
    
    input[type='text'],
    textarea[type='text'] {
      padding: 0.5rem;
      outline: none;
      font-size: 1rem;
    }

    input[type='text'] {
      border: 1px solid $greyColor;
      color: #8A8888;

      &::-webkit-input-placeholder {
        color: #8A8888;
      }
    }

    textarea[type='text'] {
      border: none;
      background-color: $groupMemBgColor;
      color: $blackColor;

      &::-webkit-input-placeholder {
        color: $blackColor;
      }
    }

    .selected_result_container {
      @include flexRow;
      gap: 0.5rem;
      width: 100%;
      flex-wrap: wrap;

      .selected_results {
        @include flexRow;
        gap: 0.25rem;
        margin-bottom: 0.25rem;
        width: fit-content;
  
        img {
          width: 2rem;
          height: 2rem;
        }
  
        .selected_result_data {
          @include flexRow;
          gap: 0.5rem;

          p {
            margin: 0%;
            font-size: 1.2rem;
          }

          small {
            color: $orangeColor;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  .del_save_btns_container {
    position: absolute;
    bottom: 0.5%;
    width: 98.5%;

    .del_btn_container {
      @include flexRow;
      justify-content: right;

      p {
        margin: 0.25rem 0;
        padding: 0;
        text-align: right;
        color: #FF223D;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .btns {
      @include flexRow;
      justify-content: space-between;
      width: 100%;

      button {
        border: none;
        color: $whiteColor;
        font-size: 1.1rem;
        font-weight: 600;
        cursor: pointer;

        &:first-child {
          background-color: $blackColor;
          width: 29.5%;
          padding: 1rem;
        }

        &:nth-child(2) {
          background-color: $orangeColor;
          width: 69.5%;
          padding: 1rem;
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .new_group_modal_container {
    .del_save_btns_container {
      bottom: 5.8%;
      width: 98%;
    }
  }
}

@media only screen and (width: 1024px) {
  .new_group_modal_container {

    .del_save_btns_container {
      bottom: 0.5%;
     }
  }
}

@media #{$media-nine-twelve} {
  .new_group_modal_container {

    .del_save_btns_container {
      width: 97.5%;
      bottom: 0.5%;
    }
  }
}

@media #{$media-eight-twenty} {
  .new_group_modal_container {
    width: 97.2%;

    .del_save_btns_container {
      bottom: 0.5%;
    }
  }
}

@media only screen and (width: 768px) {
  .new_group_modal_container {
    width: 99%;

    .del_save_btns_container {
      bottom: 7%;
     }
  }
}

@media #{$media-five-fourty} {
  .new_group_modal_container {
    width: 98.5%;

    .del_save_btns_container {
     bottom: 7%;
    }
  }
}

@media #{$media-three-seventy-five} {
  .new_group_modal_container {

    .del_save_btns_container {
     bottom: 7.5%;
    }
  }
}

@media #{$media-three-sixty} {
  .new_group_modal_container {

    .del_save_btns_container {
     bottom: 6.5%;
    }
  }
}

@media #{$media-two-eighty} {
  .new_group_modal_container {
    .del_save_btns_container {
      width: 96.5%;
    }
  }
}

@media only screen and (height: 812px) {
  .new_group_modal_container {

    .del_save_btns_container {
      bottom: 6.5%;
     }
  }
}

@media only screen and (height: 736px) {
  .new_group_modal_container {

    .del_save_btns_container {
      bottom: 7%;
     }
  }
}

