.questions-desc-container {
  .question-desc {
    margin: 1rem;
    .question-header {
      background-color: $lightGreyColor;

      .questions-role {
        font-weight: bold;
        font-size: 10px;
      }
      .question-tech-roles {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .nestria-chat-section {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }

    .write-msg {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;

      .write-msg_notes {
        color: rgb(138, 136, 136);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(224, 224, 224);
        border-image: initial;
        height: 10rem;
        background-color: $greyColor;
        text-align: center;
      }
    }
  }
}
