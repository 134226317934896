.pub_discussion_container {
  max-height: 100%;
  overflow: hidden auto;
  background-color: $lightGreyColor;

  .disc_user_container {
    @include flexRow;
    justify-content: space-between;
    border-bottom: 0.063rem solid $greyColor;
  
    .disc_user_card {
      width: 97%;
      @include flexColumn;
      padding: 0.625rem 0.625rem 0.625rem 0.25rem;
    
      .disc_user_data {
        @include flexRow;
        width: 150%;
    
        .disc_user_avatar {
          width: 4.8rem;
          height: 4.3rem;
          @include flexRow;
          justify-content: center;
          align-items: center;
          padding-top: 0.5rem;
          margin-top: -0.3rem;
          margin-right: 0.6rem;
      
          .disc_avatar_image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .active_disc_user_avatar {
          margin-left: 0.1rem;
        }

        .inactive_disc_user_avatar {
          margin-left: 0.4rem;
        }
      
        .disc-info-section {
          width: 95%;
          height: 100%;
          @include flexColumn;
          font-size: smaller;
          overflow: hidden;
      
          p {
            margin: 0%;
            font-weight: 600;
            font-size: 12px;
            color: $blackColor;
      
            small {
              font-weight: 500 !important;
              font-size: 0.656rem;
            }
          }
      
          .last_msg {
            font-size: 10px;
            color: $lastMsgTextColor;
            font-weight: 400;
            margin-top: 0.25rem;
            width: 70%;
          }
      
          .edit_groupName {
            @include flexRow;
      
            .edit_groupName_input {
              max-width: 50%;
              font-size: 0.85rem;
        
              &:focus {
                outline: none;
              }
            }
          }
          
          
          .edit_groupName_Btns {
            margin: 0 0.1rem 0 auto;
            @include flexRow;
      
            .save,
            .cancel,
            .disable {
              border: none;
              border-radius: 0.313rem;
              color: white;
              font-size: 0.85rem;
              font-weight: bold;
              padding: 0 0.5rem;
            }
      
            .save,
            .cancel {
              cursor: pointer;
            }
            
            .save {
              background-color: green;
              margin-left: 0.188rem;
      
              &:hover {
                background-color: rgb(2, 68, 2);
              }
            }
            
            .cancel {
              background-color: red;
              margin-left: 0.063rem;
      
              &:hover {
                background-color: rgb(169, 5, 5);
              }
            }
      
            .disable {
              background-color: #6F6F6F;
              pointer-events: none;
            }
          }
      
          .groupName_container {
            display: flex;
      
            .new_msg_icon {
              width: 1rem;
              height: 0.85rem;
              margin: 0 0 -0.15rem 1rem;
            }
          }
      
          .double_click_to_edit {
            color: #393b3f;
            font-size:  0.625rem;
            font-weight: bold;
          }
        }
      }
    
      .channel_container {
        @include flexColumn;
        width: fit-content;
    
        .channel {
          position: relative;
        }
    
        .show_more {
          font-size: 0.85rem;
          font-weight: 400;
          color: $blackColor;
          cursor: pointer;
        }
      }
    }
  
    .chat_group_options {
      @include flexRow;
      margin: 0.8rem 0 0 0;
      height: fit-content;
  
      .unread_msg {
        width: 2.5rem;
        margin: auto 0 0.4rem auto;
        background-color: $blackColor;
        color: $whiteColor;
        font-size: 0.8rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .options_icon {
        cursor: pointer;
      }
    }
  }
}

.pub_discussion_container::-webkit-scrollbar {
  width: 0.3rem;
}

@media #{$media-desktop-plus} {
  .pub_discussion_container {
    height: 93.5%;

    .disc_user_container {
    
      .disc_user_card {
        width: 100%;
    
        .groupName_container {
          .unread_msg {
            margin-right: 1.75rem !important;
          }
        }
    
        .disc-info-section {
          .last_msg {
            font-size: 1.1rem;
            width: 65% !important;
          }
        }
    
        .channel_container {
          margin-left: 3%;
        }
      }
    
      .chat_group_options {
        margin: 0.8rem 0.5rem 0 0;
      }
    }
  }
}
