@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

.admin-user_view_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: "Manrope";

  .admin-user_view_container::-webkit-scrollbar {
    width: 0.1rem;
  }

  .admin-user_view-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .user_units_container {
      /* margin: 1rem; */
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      background-color: #f8f8f8;
      width: 49%;
      height: 100%;

      .back_btn {
        align-self: left;
        width: fit-content;
        @include flexRow;
        gap: 0.5rem;
        // margin-bottom: 2rem;
        padding: 0.5rem 0;
        color: #000000;
        /* margin-top: 2rem; */
        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 0.871rem;
          line-height: 0.308rem;
          margin: 0;
          /* or 39% */

          display: flex;
          align-items: center;

          color: #000000;
        }
      }

      .user_units {
        /* height: 85%; */
        /* height: 85%; */
        overflow-x: hidden;
        overflow-y: auto;
        height: 74.5%;
        margin-bottom: 0.5rem;

        .courseData{
          display: grid;
          align-items: center;
          padding: 0.5rem;

          h2{
            font-weight: 800;
            font-size: 1.5rem;
            color: #000000;
            padding: 0.1rem;
            padding-bottom: 0.3rem;
            justify-self: center;
          }

          .courseScores{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.1rem;

            p{
              font-weight: 600;
              font-size: 1.2rem;
              color: #000000;
              padding: 0.1rem;
            }
          }
        }

        .unit_info {
          /* display: flex; */
          /* border: 4px solid yellowgreen; */
          /* gap: 0.5rem; */

          .unit {
            display: flex;
            /* flex-direction: column; */
            align-items: center;
            gap: 0.3rem;
            padding: 0.5rem;

            .avatar-image {
              width: 1.8rem; /* Make the avatar image fill the container */
              height: 1.8rem; /* Maintain aspect ratio */
              border-radius: 50%;
            }
          }

          .unit-title-description {
            padding: 0.5rem;

            .unit-header {
              font-family: "Manrope";
              font-style: normal;
              font-weight: 600;
              font-size: 1.2rem;
              align-items: center;
              padding: 0.1rem;
              color: #000000;
              margin: 0;

              .unitScore{
                display: flex;
                justify-content: space-between;

                p{
                  font-size: 1rem;
                  padding-right: 0.5rem;
                }
              }
            }
            .personal-work {
              font-family: "Manrope";
              font-style: normal;
              font-weight: 400;
              font-size: 0.825rem;
              padding: 0.1rem;
              color: #8a8888;
              margin: 0;
            }
          }
        }

        .unit_assigned_questions {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0.5rem 0.5rem;
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 0.664rem;
          .question {
            padding: 0.1rem 0.3rem;
            font-size: 0.856rem;
            margin-bottom: 0.4rem;
            width: 100%;
          }

          .question.selected {
            background-color: rgb(224, 224, 224);
            width: 100%;
            padding: 0.1rem 0.3rem;
            margin-bottom: 0.4rem;
          }

          .question.selected.non-clickable {
            opacity: 0.5;
          }
          .question.non-selected.non-clickable {
            opacity: 0.5;
          }

          .question-text {
            display: flex;
            gap: 10px;
            width: 100%;
            justify-content: space-between;
          }
          .question-xp {
            font-weight: bold;
            color: black;
            display: inline-block;
            padding: 3px 3px;
            width: 22px;
            height: 22px;
            background-color: #8a8888;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .user_units::-webkit-scrollbar {
        width: 0.1rem; /* Set the width of the scrollbar */
      }
      .search-unit-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #d9d9d9;
        margin-bottom: 0.1rem;

        .unit-list {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          //background-color: #d9d9d9;
          align-items: center;
          padding: 0.1rem;

          .available-units {
            display: flex;
            gap: 0.5rem;
            flex-direction: row;

            .unit-text {
              font-family: "Manrope, sans-serif";
              font-style: normal;
              font-weight: 400;
              font-size: 0.889rem;
              color: #000000;
            }
          }
        }
      }

      .search-unit-container::-webkit-scrollbar {
        width: 0.1rem; /* Set the width of the scrollbar */
      }

      .unit_search_box_container {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 0.6642rem; /* converted from 10.6369px */
        line-height: 0.25rem; /* converted from 4px or 37% */

        display: flex;
        align-items: center;

        color: #000000;
        input {
          color: #000000;
          width: 100%;
          padding: 0.5rem 0.5rem;
          outline: 0;
          font-size: 0.85rem;
          font-weight: 550;
          /* height: 50%; */
          border: 0.063rem solid #ded9d9;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAnCAYAAABjYToLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIRSURBVHgB7ZixUcMwFIb/wAIZQRvEbCAmCBsgSqqwQcwEZIOECYAJ7HR0gQniki6howPpLF+M/GRLtkRS8N3955Miy7+eXmTJI7gzlhJSEymuy2P9217qTWst9Yw/gEtlUt8e2kktpRgioKLx4GmI0hwBSaS2AUxV2sAzeiOLqQyH/KmjcukRh3za63qmNZW6Ak0hdamv3jCU+UHlTGoxS/WRgo7cFj3yjoGevidHQ679ZfBkSXSywDDUgDZEv3euHTDQkQqBMmdGbgfHWTCj1SsXWuBoDjztuokRNwmEZ4Vm1FoRaEYrBhzNAHBb4zOUa0+dHHHIUa59dRJbY2WMGXUviMfaKE9sDSlje8SjMMrM1lAZM/+2sXJM4TzoM5woylhh1F0gHswof9oaUsYY4mEme2FrqIy9G3VTxIMb5byrsfPCNwBBPKfzfWnuwTKEx3yRL11uStEczTXCMUfPWbFtTRIMZ0aYcopWBQe9FR5ijjKlBszgyQJhjmBtRz/n3avJCvaDhED7aDnKQexgP8o5TePIUq8iN2u5rzq6Fbo8xuGzgQsrqRv0JAWcD7RtUpGmIuibHr9gsE9tl+pn0SSGubrBrYOhDPTh2MucLce6TCb6Wj28QJlzOdr3XLbPD6nUPY6MQJglKQoC/+b6IRD47RASZaKx1Jzj+LyiXB14re7jFIwpcpTm1HLyJXX7Ay2dSz9iQZknAAAAAElFTkSuQmCC);
          background-position: right 0.5rem center;
          background-repeat: no-repeat;
          background-size: 1.3rem 1.3rem;
          /* line-height: 1.5;*/
        }
      }
    }

    .question-view {
      //margin: 0.5rem;
      width: 50%;
      height: 100%;

      .back_btn {
        align-self: left;
        width: fit-content;
        @include flexRow;
        gap: 0.2rem;
        //margin-bottom: 1rem;
        color: #000000;
        //margin-top: 0.5rem;
        padding: 0.5rem 0;
        p {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 0.871rem;
          line-height: 0.308rem;
          margin: 0;
          /* or 39% */

          display: flex;
          align-items: center;

          color: #000000;
        }
      }

      .question-header {
        background-color: #f8f8f8;
        height: 8rem;
        padding: 0.446rem;

        .questions-role {
          /* margin-top: 2rem; */
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 0.737rem;
          line-height: 1.125rem;
          padding: 0.5rem;

          label {
            margin: 0;
            color: #000000;
            font-weight: 600;
            font-size: 0.864rem;
            line-height: 0.8rem;
          }
        }
        .question-tech-roles {
          display: flex;
          align-items: center;
          gap: 1.25rem;
          //margin-top: 0.625rem;
          padding: 0.5rem;
          margin-bottom: 0.625rem;

          .member {
            display: flex;
            gap: 0.5rem;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 0.698rem;
            line-height: 0.625rem;
            /* or 106% */

            display: flex;
            align-items: center;

            color: #000000;
            .avatar-image {
              width: 100%; /* Make the avatar image fill the container */
              height: auto; /* Maintain aspect ratio */
              border-radius: 50%;
              height: 1.8rem;
              width: 2rem;
              flex-shrink: 0;
            }
          }
        }
      }
      .message-chat {
        height: 55.8%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 0.2rem;
        margin-top: 0.5rem;
        .nestria-chat-section {
          //margin-top: 1.25rem;
          display: flex;
          align-items: flex-start;
          gap: 0.625rem;
          //margin-bottom: 0.938rem;
          padding: 0.2rem;
          margin-bottom: 1rem;

          .circle {
            width: 2.5rem;
            flex-shrink: 0;

            .avatar-image {
              width: 100%; /* Make the avatar image fill the container */
              height: auto; /* Maintain aspect ratio */
              border-radius: 50%;
            }
          }

          .messages {
            .message-sender {
              color: #959494;
              font-weight: 600;
              font-size: 0.898rem;
              //line-height: 0.492rem;
              font-family: "Manrope";
              font-style: normal;
              line-height: 0.625rem;
              margin-bottom: 0.2rem;
            }

            .message {
              font-weight: 400;
              color: #000000;
              font-size: 0.864rem;
              /* line-height: 0.911rem; */
              padding: 0.1rem;

              .rating {
                font-weight: 700;
                font-size: 1.2rem;
              }
            }
          }
        }
      }

      .message-chat::-webkit-scrollbar {
        width: 0.1rem; /* Set the width of the scrollbar */
      }

      .write-msg {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.3rem;

        .write-msg_notes {
          color: rgb(138, 136, 136);
          border-width: 0.071rem;
          border-style: solid;
          border-color: rgb(224, 224, 224);
          border-image: initial;
          height: 4.9rem;
          background-color: #f8f8f8;
          text-align: justify;
          margin: 0;
        }
      }

      .send-btn-container {
        padding: 0.2rem 0px;
        color: white;
        background-color: #000000;
        width: 100%;
      }
    }
  }
}

.admin-user_view_container::-webkit-scrollbar {
  width: 0.3rem;
}

@media #{$media-desktop-plus} {
  .admin-user_view_container {
    width: 100vw;
    max-width: 100vw;
    height: 95vh;

    .admin-user_view-main {
      height: 100%;
      .user_units_container {
        width: 49%;
      }
      .question-view {
        margin: 0.889rem;
        width: 49%;
      }
    }
  }
}
