.nav_container {
  width: 53vw;
  max-width: 53vw;
  margin-bottom: -0.65rem;

  .nav {
    @include flexRow;
    border-radius: 2%;
    background-color: #F4F7FA;
    padding: 0 0 1rem 0;

    .nav_title {
      @include flexRow;
      width: 100%;
      font-weight: bold;
      align-items: center;
      font-size: 1rem;

      .checkin_sub_container {
        @include flexRow;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }

      .spryte_logo,
      .discussion,
      .connections,
      .requests,
      .checkins {
        @include flexRow;
        justify-content: center;
        gap: 0.5rem;
      }

      .discussion,
      .connections,
      .requests,
      .checkins {
        width: fit-content;
        padding: 0 0.5rem;
        max-height: fit-content;
      }

      .spryte_logo {
        @include flexRow;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: fit-content;
        max-width: fit-content;
        padding: 0.55rem !important;

        img {
          width: 2.3rem;
          height: 2.3rem;
        }
      }

      .checkins {
        border-right: none !important;
      }

      .settings {
        @include flexRow;
        margin-left: auto;
        margin-bottom: -0.3rem;
        height: fit-content;
        width: fit-content;
        max-width: fit-content;
  
        .user_avatar {
          @include flexRow;
          justify-content: center;
          align-items: center;
          width: 3.9rem;
          height: 3.7rem;
          overflow: hidden;
          border-left: 1px solid #F1F1F1;
          cursor: pointer;
  
          img {
            width: 70%;
            height: 70%;
            border-radius: 50%;
          }
        }
  
        .nav_status_container {
          position: relative;
          right: 0.45rem;
          top: 0rem;
  
          .nav_status {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            margin: 0;
            padding: 0;
            position: absolute;
            overflow: hidden;
            background-color: $greenColor;
            z-index: 99;
            cursor: pointer;
          }
        }
      }

      .active_tab,
      .active_settings {
        background-color: #F6F6F6;
        border-top: 2px solid #808080;
        border-right: 1px solid #F1F1F1;
        border-bottom: 2px solid #F6F6F6;
      }

      .inactive_tab {
        background-color: $whiteColor;
        border-top: 2px solid $whiteColor;
        border-right: 1px solid #F1F1F1;
        border-bottom: 2px solid $whiteColor;
        cursor: pointer;
      }

      .inactive_settings {
        background-color: $whiteColor;
        cursor: pointer;
      }

      .desktop {
        margin: 0%;
      }

      .isActive,
      .inactive {
        color: $blackColor;
        padding: 1rem 0 1rem 0;
        font-weight: 600 !important;
      }

      .inactive {
        cursor: pointer;
      }

      .active_counts,
      .counts,
      .big_counts,
      .active_big_counts {
        font-size: 0.75rem;
        font-weight: 700;
        // display: inline-block; // sets display property to inline-block so that width and height properties can apply
        // width: fit-content;
        width: 1rem;
        height: fit-content;
        border-radius: 1.5rem;
        text-align: center;
        padding: 0 0.5rem;
        margin: auto 0;
      }

      @-moz-document url-prefix() { // styles specific to mozilla firefox only
        .active_counts,
        .counts,
        .big_counts,
        .active_big_counts {
          height: fit-content !important;
          font-size: 0.65rem !important;
        }
      }

      .counts,
      .active_counts,
      .active_big_counts {
        background-color: $orangeColor;
        color: $whiteColor;
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .nav_container {
    width: 100vw;
    max-width: 100vw;
    margin-top: -1.8%;

    .nav {

      .nav_title {
        @include flexRow;
        justify-content: space-between;
        width: 100%;
        margin: 0;

        .spryte_logo,
        .discussion,
        .connections,
        .requests,
        .checkins,
        .settings {
          flex-grow: 1;
        }

        .active_counts,
        .counts,
        .big_counts,
        .active_big_counts {
          font-size: 0.65rem;
        }

      }
    }
  }
}

@media screen and (width: 1024px) {
  .nav_container {
    .nav {
      .nav_title {
        .spryte_logo {  
          img {
            width: 3.4rem;
            height: 3.4rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1040px) {
  .nav_container {
    width: 100%;
    max-width: 100%;

    .nav {
      .nav_title {
        .discussion,
        .connections,
        .requests,
        .checkins {
          width: 20%;
        }

        .spryte_logo,
        .settings {
          width: 10%;
        }

        .settings {
          width: 4rem;
          height: 3.8rem;

          .user_avatar {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (width: 820px), (width: 912px) {
  .nav_container {
    width: 100%;
    max-width: 100%;

    .nav {

      .nav_title {
        
        .discussion,
        .connections,
        .requests,
        .checkins {
          @include flexColumn;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          width: 20%;
          gap: 0;
          // padding: 0.55rem 0;
          padding: 1rem 0;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .requests {
          padding: 0.55rem 0;
        }

        // .checkins {
        //   padding: 1rem 0;
        // }

        .spryte_logo,
        .settings {
          width: 10%;
          padding: 0;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .settings {
          width: 3.8rem;
          height: 3.8rem;

          .user_avatar {
            width: 3.8rem;
            height: 3.8rem;
          }
        }
      }
    }
  }
}

@media #{$media-seven-sixty-eight} {
  .nav_container {
    margin-top: -1%;
  }
}

@media #{$media-five-fourty} {
  .nav_container {
    margin-top: -1.5%;
  }
}

@media screen and (max-width: 414px) {
  .nav_container {
    .nav {
      .nav_title {
        .spryte_logo,
        .discussion,
        .connections,
        .requests,
        .checkins,
        .settings {
          flex-grow: 1;
          padding: 0 0.15rem;
        }
      }
    }
  }
}


@media #{$media-two-eighty} {
  .nav_container {
    width: 100%;
    max-width: 100%;
    margin-top: -2%;

    .nav {

      .nav_title {
        
        .discussion,
        .connections,
        .requests,
        .checkins {
          @include flexColumn;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          width: 20%;
          gap: 0;
          padding: 1rem 0;

          p {
            margin: 0;
            padding: 0;
            font-size: 0.85rem;
          }
        }

        .requests {
          padding: 0.65rem 0;
        }

        .spryte_logo,
        .settings {
          width: 10%;

          p {
            margin: 0;
            padding: 0;
          }
        }

        .settings {

          .user_avatar {
            width: 3.8rem;
            height: 3.8rem;
          }
        }
      }
    }
  }
}
