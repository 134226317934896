.disc-center-div {
  display: flex;
  flex-direction: column;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: 'Manrope', sans-serif;
  border-radius: 1%;

  .disc_main {
    background-color: rgb(255, 255, 255);
    border-radius: 1%;
    height: 100%;
    margin-top: 0.1rem;

    .disc-section {
      display: flex;
      height: 99%;
      width: auto;
      margin: 0 0.25em 0 0.25em;

      .disc-left-section {
        width: 50%;
        display: flex;
        height: 95%;
        max-height: 100%;
        flex-direction: column;
      }
      
      .disc-right-section {
        width: 50%;
        display: flex;
        height: 100%;
        flex-direction: column;

        .previous_icon {
          display: none;

          p {
            color: $blackColor;
            font-weight: 600;
            margin: 0 0.25rem;
          }
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .disc-center-div {
    .disc_main {
      .disc-section {
        // margin: 0.5em 0 0 0;
        margin: 0;

        .disc-left-section {
          width: 100%;
          // height: fit-content;
          height: 97%;
          max-height: 100%;
          border-right: 1px solid $borderColor;
        }
      
        .disc-right-section {
          width: 100%;
          // height: 107%;

          .previous_icon {
            display: flex !important;
            margin: 0.5rem 0;
            padding: 0.4rem 0 0.25rem 0;
            width: fit-content;
            cursor: pointer;
          }
        }
      
        .left_mobile {
          display: none;
        }
      
        .right_mobile {
          display: none;
        }
      }
    }
  }
}

@media #{$media-tablet} {
  .disc-center-div {
    width: 100vw;
    max-width: 100vw;
    height: 95vh;
  }
}

@media #{$media-desktop-plus} {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 96%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}

@media #{$media-three-seventy-five}, #{$media-one-zero-two-four} {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 93%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}

@media #{$media-three-sixty} {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 94.5%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}

@media #{$media-two-eighty} {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 93%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (height: 812px), (height: 1366px) {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 96%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (height: 736px) {
  .disc-center-div {
    .disc_main {
      .disc-section {
        height: 95%;

        .disc-left-section {
          height: 100%;
        }
      }
    }
  }
}
