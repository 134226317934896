.login_contents {
  width: 53vw;
  max-width: 53vw;
  // height: 100dvh;
  position: fixed;
  bottom: 7.5rem;
  right: 6.25rem;
  font-family: duo-round, sans-serif;
  border-radius: 1%;
  background: linear-gradient(146.18deg, #00c1aa 0.99%, $oratioBlue2 100%);
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
    0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
    0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);

  .spryte {
    @include flexRow;
    align-items: center;

    h3 {
      color: #ffffff;
      opacity: 0.925;
      font-size: 1.8rem;
      font-weight: 800;
      letter-spacing: -0.063rem;
      margin-right: 0.313rem;
    }
  }

  .login_container {
    min-height: 28.125rem;
    font-family: duo-round, sans-serif;
    border-radius: 1%;
    padding: 0.938rem 1.875rem;

    .logo_container {
      width: 3.438rem;
      margin: 0 auto;

      img {
        width: 4.375rem;
        height: 4.375rem;
        margin: 1.563rem 0 -2.188rem 0;
      }
    }

    h3 {
      font-size: 2.125rem;
      font-weight: 800;
      text-align: center;
      // opacity: .85;
      color: #ffffff;
    }

    span {
      opacity: 0.85;
    }

    .input_container {
      @include flexColumn;
      width: 90%;
      margin: 1.25rem auto;

      label {
        font-size: 19px;
        font-weight: 700;
        color: #ffffff;
        opacity: 0.85;
        margin-bottom: 0.3rem;
      }

      input {
        height: 2.188rem;
        padding: 0.188rem;
        font-size: 1rem;
        border-radius: 0.313rem;
        outline: none;
        border: 1px solid rgb(204, 204, 204);
        padding: 0.313rem 0.625rem;
      }

      img {
        width: 1.6rem;
        position: relative;
        left: 94%;
        bottom: 1.8rem;
        cursor: pointer;
      }

      .shown_password {
        filter: brightness(0.25);
      }

      span {
        color: #ffffff;
      }

      .password_error_msg {
        margin-top: -1.188rem;
      }
    }

    .signIn_btn {
      margin: 2.5rem auto 1rem auto;
      text-align: center;

      button {
        color: #fff;
        background: #4cc4eb;
        border-radius: 5%;
        border: none;
        padding: 0.5rem 0.7rem;
        margin-bottom: 3rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0 2px 0 #4cc4eb;
        width: 90%;
      }

      .forgot_password_account {
        color: #ffffff;

        p {
          &:first-child {
            font-size: 1.125rem;
            font-weight: 600;
            cursor: pointer;
          }

          &:nth-child(2) {
            font-weight: 400;
            overflow: hidden;
          }

          &:nth-child(2):before {
            content: "";
            display: inline-block;
            height: 0.8rem;
            vertical-align: bottom;
            width: 20%;
            margin-left: -100%;
            margin-right: 0.625rem;
            border-top: 0.063rem solid #fbfbfb;
          }

          &:nth-child(2):after {
            content: "";
            display: inline-block;
            height: 0.8em;
            vertical-align: bottom;
            width: 20%;
            margin-right: -100%;
            margin-left: 0.625rem;
            border-top: 0.063rem solid #fbfbfb;
          }
        }
      }

      .register_btn_container {
        a,
        a:hover,
        a:focus,
        a:active {
          text-decoration: none;
          color: inherit;

          button {
            padding: 1.2rem 8.5rem;
          }
        }
      }
    }
  }
}

@media #{$media-tablet} {
  .login_contents {
    width: 100vw;
    max-width: 100vw;
    height: 100dvh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .login_container {
      .input_container {
        img {
          left: 87%;
        }
      }
    }
  }
}
