:root {
  --font-family: "Manrope", sans-serif; /* 8.20356px */
  --font-style: normal; /* 10.9381px */
}

.create-ques-container {
  width: 97.9%;
  height: 99%;
  background-color: $whiteColor;
  position: absolute;
  padding: 0 0.4rem;
  z-index: 100000;
}

.create-ques-main {
  height: 100%;
}

.back_btn {
  align-self: left;
  width: fit-content;
  @include flexRow;
  gap: 0.2rem;
  //margin-bottom: 1rem;
  color: #000000;
  //margin-top: 0.5rem;
  padding: 0.5rem 0;
  p {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 0.871rem;
    line-height: 0.308rem;
    margin: 0;
    /* or 39% */

    display: flex;
    align-items: center;

    color: #000000;
  }
}

.create-ques-header {
  @include flexRow;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
}

.header {
  @include flexRow;
  margin-bottom: 0.5rem;
  gap: 0.3rem;
}

label {
  margin: 0;
  color: $blackColor;
  font-weight: 600;
  font-size: 1rem; /* Updated font size */
  line-height: 1rem; /* Updated font size */
}

.toggle_switch_container {
  @include flexRow;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;

  p {
    margin: 0;
    font-size: 0.769rem;
    line-height: 0.3125rem;

    &:first-child {
      color: #8a8888;
    }

    &:nth-child(2) {
      color: $blackColor;
    }
  }

  .toggle_switch {
    position: relative;
    bottom: 0.1rem;
    display: inline-block;
    width: 1.986875rem;

    height: 1.068125rem;
    background-color: #f5f5f5;
    border-radius: 1.7rem;
    cursor: pointer;

    .toggle_handle {
      position: absolute;
      top: 1.9px;
      right: 1.8px;
      width: 1rem;
      height: 1rem;
      background-color: #000000;
      border-radius: 50%;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
      transition: transform 0.2s ease;
    }
  }

  .toggle_switch.on {
    background-color: $greenColor;

    .toggle_handle {
      transform: translateX(-20px);
    }
  }

  .toggle_switch.off {
    background-color: #d9d9d9;
  }
}

.ques-container,
.gpt-prompt-container,
.admin-notes-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ques-container label,
.gpt-prompt-container label {
  margin: 0;
  color: #000000;
  font-weight: 600;
  font-size: 0.9rem; /* Updated font size */
}

.ques_input_message,
.admin_notes {
  color: rgb(138, 136, 136);
  border-width: 1px;
  border-style: solid;
  border-color: 0.5px solid #e4e4e4; /* Updated border width */
  border-image: initial;
}

.gpt_input_message {
  color: rgb(138, 136, 136);
  border-width: 1px;
  border-style: solid;
  border-color: 0.5px solid #e4e4e4; /* Updated border width */
  border-image: initial;
  resize: vertical; /* Allow vertical resizing */
  min-height: 4.5rem; /* Set a minimum height for the text areas */
  max-height: 11.5rem; /* Set a maximum height for the text areas */
  overflow-y: auto; /* Add vertical scrollbars if the content exceeds the maximum height */
}

.ques_input_message {
  font-weight: 400;
  font-size: 1.1rem; /* Updated font size */
  padding: 0.2rem; /* Add padding to adjust text position */
  text-align: left; /* Align text to the left */
  white-space: normal; /* Allow text wrapping */
}

.error-message {
  color: red;
  font-size: 0.8rem; /* Updated font size */
  margin-top: 5px;
}

.gpt_input_message {
  height: 9.5rem; /* Updated height */
  font-weight: 400;
  font-size: 1.1rem; /* Updated font size */
}

.admin_notes {
  height: 5.5rem; /* Updated height */
  background-color: #f8f8f8;
  text-align: left;
  font-weight: 400;
  font-size: 1.2rem; /* Updated font size */
}

.gpt-prompt-container {
  margin-bottom: 7.5rem; /* Updated margin */
}

.ques-container {
  margin-bottom: 0.5rem; /* Updated margin */
}

.admin-notes-container {
  margin-bottom: 0.8rem; /* Updated margin */
}

.test-btn-container {
  text-align: right;
  margin-bottom: 6rem;
  .button {
    display: inline-block;
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
    background-color: $blackColor;
    color: white;
    font-weight: 600;
    font-size: 11.6217px;
  }
}

.delete-ques-container {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-bottom: 0.2rem; /* Updated margin */

  p {
    margin: 0.3rem 0; /* Updated margin */
    padding: 0;
    text-align: right;
    color: #ff223d;
    font-weight: 400;
    font-size: 1.2rem; /* Updated font size */
    cursor: pointer;
  }
}

.create_save_btn {
  width: 99%;
  box-sizing: border-box;
  position: absolute;
  background: #000000;
  border: 0.5px solid #e4e4e4; /* Updated border width */
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2rem; /* Updated padding */
  background-color: $blackColor;
  color: white;
  height: 1.2rem; /* Updated height */
  text-align: center;
  font-size: 1.3rem; /* Updated font size */
  cursor: pointer;
}
