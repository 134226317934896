.email_textarea_container {
  border: 1px solid $borderColor;
  border-radius: 5px;
  width: 94%;
  margin: 0 auto 0.25rem auto;
  color: $blackColor;
  background-color: $groupMemBgColor;

  .to_container {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-radius: 5px;

    input {
      width: 60%;
      background-color: $groupMemBgColor;
    }

    .cc_bcc_btns {
      margin: 0 0.5rem 0 auto;
      align-self: center;

      .cc,
      .bcc {
        cursor: pointer;
      }

      .cc {
        margin-right: 0.5rem;
      }
    }
  }

  .subject_container {
    border: 1px solid $borderColor;
    border-radius: 5px;

    input {
      width: 100%;
      background-color: $groupMemBgColor;
    }
  }

  .to_container,
  .subject_container {
    @include flexRow;

    label {
      padding: 0.5rem 0.75rem;
    }

    input {
      border: none;
      padding: 0.3rem 0.25rem;
      font-size: 0.85rem;
      color: $blackColor;

      &:focus {
        outline: none;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $blackColor;
      }
    }
  }

  .textarea_container {
    textarea {
      border: none;
      padding: 0.5rem 0.75rem;
      width: 95%;
      min-height: 5rem;
      font-size: 1rem;
      color: $blackColor;
      background-color: $groupMemBgColor;

      &:focus {
        outline: none;
      }
    }

    textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: "Manrope", sans-serif;
      font-size: 1rem;
      color: $blackColor;
    } 
  }

  .cc_bcc_input_container {
    @include flexColumn;
    margin-left: 0.75rem;

    label {
      width: 65%;
      @include flexRow;

      input {
        width: 85%;
        margin-left: auto;
        border: none;
        font-size: 0.85rem;
        color: $textColor;
        padding: 0 0.8rem;
        background-color: $groupMemBgColor;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .email_textarea_container {
    margin: 0 auto -1rem auto;
    
    .textarea_container {
      textarea {
       font-size: 1rem;
       color: #959494;
       font-weight: 700;
      }
    }
  }
}
