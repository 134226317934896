.edit_timePicker_container {
  position: relative;
  bottom: 70%;
  left: 3%;
  width: 100%;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 98;
  }

  .edit_timePicker_modal {
    position: absolute;
    z-index: 99;
    min-width: 90%;
    max-width: 95%;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.313rem;
    padding: 1rem;
    padding-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.313rem 0.938rem;
  }
}

@media #{$media-desktop-plus} {
  .edit_timePicker_container {
    left: 2.4%;
  }
}

@media only screen and (width: 1280px) {
  .edit_timePicker_container {
    bottom: 65%;
    left: 3%;
  }
}

@media #{$media-one-zero-two-four} {
  .edit_timePicker_container {
    bottom: 72%;
    left: 1%;
  }
}

@media #{$media-five-fourty} {
  .edit_timePicker_container {
    left: 0.8%;
  }
}

@media only screen and (width: 414px) {
  .edit_timePicker_container {
    bottom: 80%;
  }
}

@media #{$media-three-sixty}, #{$media-three-seventy-five} {
  .edit_timePicker_container {
    bottom: 40%;
  }
}

@media #{$media-two-eighty} {
  .edit_timePicker_container {
    left: 0;
  }
}

@media only screen and (height: 1366px) {
  .edit_timePicker_container {
    bottom: 60%;
    left: 1%;
  }
}
