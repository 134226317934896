.msg_textarea_container {
  width: 94.5%;
  margin: 0 auto 0.25rem auto;

  .input_message {
    border: 1px solid $borderColor;
    border-radius: 0.313rem;
    overflow: auto;
    outline: none;
    font-family: "Manrope", sans-serif;
    font-size: 0.75rem;
    box-shadow: none;
    background-color: $groupMemBgColor;
    resize: none;
    height: 80%;
    width: 96%;
    padding: 0.5rem;
    margin-top: 0.125rem;
  }

  textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $blackColor;
    font-size: 1.2rem;
  }   
}

@media #{$media-desktop-plus} {  
  .msg_textarea_container {
    height: 10rem;

    .input_message {
      width: 97%;
      height: 100%;
      font-size: 1.2rem;
      color: $msgMetaDataColor;
      font-weight: 700;
    }
  }
}

@media only screen and (width: 1280px) {
  .msg_textarea_container {
    .input_message {
      height: 100% !important;
    }
  }
}

@media only screen and (min-width: 1024px) {  
  .msg_textarea_container {
    .input_message {
      height: 80%;
      width: 96.5% !important;
      font-size: 1.2rem;
      font-weight: 400;
      color: $blackColor;
      background-color: $groupMemBgColor;
    }
  }
}

@media #{$media-nine-twelve} {
  .msg_textarea_container {
    .input_message {
      width: 96% !important;
    }
  }
}

@media #{$media-eight-twenty} {
  .msg_textarea_container {
    .input_message {
      width: 96% !important;
    }
  }
}


@media screen and (min-width: 769px) and (max-width: 1040px) {
  .msg_textarea_container {
    .input_message {
      width: 96% !important;
    }
  }
}

@media only screen and (width: 768px) {  
  .msg_textarea_container {
    .input_message {
      width: 98%;
    }
  }
}

@media only screen and (width: 540px) {  
  .msg_textarea_container{
    .input_message {
      height: 65%;
    }
  }
}

@media only screen and (max-width: 375px) {
  .msg_textarea_container {
    .input_message {
      height: 95%;
    }
  }
}

@media only screen and (max-width: 360px) {
  .msg_textarea_container {
    margin: 0 auto -1rem auto;
  }
}
