.center-div {
  @include flexColumn;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: 'Manrope', sans-serif;

  .main_settings {
    background-color: rgb(255, 255, 255);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 1%;
    // padding: 10px 0;
    position: relative;

    .user_settings {
      margin: 0.625rem 0 0 1.563rem;
      max-width: 9.375rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: #000000;
    }

    .settings_modal {
      @include flexRow;
      align-items: center;
      margin: 0 1.563rem;
      border-bottom: 0.5px solid #cbcbcb;
    
      .profile_container {
        @include flexColumn;
    
        img {
        width: 4.063rem;
        height: 4.063rem;
        border-radius: 50%;
        margin-left: 0.625rem;
        }
  
        label {
          font-size: 0.75rem;
          width: fit-content;
          background-color: $settingsBtnBgColor;
          color: $oratioBlue2;
          font-weight: 500;
          border: none;
          padding: 0.25rem 1rem;
          margin: 0.25rem 0.75rem 0.25rem 0rem;
          cursor: pointer
        }
    
        input[type='file'] {
          max-width: 5.625rem;
          margin-right: 0.938rem;
        }
    
        button {
          width: 3.125rem;
          align-self: center;
          margin: -2rem 0.938rem 0 -0.313rem;
          padding: 0.188rem;
          background-color: $oratioBlue2;
          color: $whiteColor;
          font-size: 0.85rem;
          font-weight: 600;
          border: none;
          border-radius: 0.313rem;
          cursor: pointer;
        }
      }
    
      .profile_details {
        @include flexColumn;
        line-height: 1;
  
        .name_status_container {
          @include flexRow;
  
           .first_name {
              border-right: 1px solid #989898;
              padding-right: 0.625rem;
              color: #989898;

              b {
                color: #4960E1;
                font-weight: 600;
              }
            }
  
            .last_name {
              border-right: 1px solid #989898;
              padding-right: 0.75rem;
              margin-left: 0.75rem;
              color: #989898;

              b {
                color: #4960E1;
                font-weight: 600;
              }
            }
  
          .first_pencil,
          .last_pencil {
            position: absolute;
            top: 3.5rem;
            width: 1.875rem;
            z-index: 2;
            cursor: pointer;
          }
  
          .first_pencil {
            left: 12.2rem;
          }
  
          .last_pencil {
            left: 19.375rem;
          }
    
          .first_container,
          .last_container {
            @include flexRow;
            position: relative;
            bottom: 1rem;
            box-shadow: rgb(0 0 0 / 35%) 0px 3px 0.313rem;
    
            input {
              min-height: 1rem;
              width: 80%;
              padding: 0.125rem 0.313rem;
              border: none;
              outline: none;
              font-size: 0.85rem;;
            }
  
            button {
              color: $whiteColor;
              font-weight: 600;
              border: none;
              cursor: pointer;
              padding: 0.5rem;
              font-size: 0.85rem;
            }

            .cancel {
              margin-right: 0.188rem;
              background-color: $darkGreyColor;
            }
  
            .save {
              background-color: $greenColor;
              padding: 0.5rem;
              font-size: 0.85rem;
            }
          }

          .current_status {
            margin-left: 0.75rem;
            color: #989898;
            cursor: pointer;

            .status_container{
              .status_header {
                @include flexRow;
              }

              .status {
                @include flexRow;
                margin-top: -0.9rem;

                .status_indicator {
                  width: 0.7rem;
                  height: 0.7rem;
                  border-radius: 50%;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  background-color: #07CF1B;
                }

                .my_status {
                  margin-left: 0.5rem;
                }
              }
            }
          }
        }
  
        .aboutMe {
          color: #989898;

          .edit_Btn {
            background: $settingsBtnBgColor;
            border: none;
            outline: none;
            margin-left: 0.5rem;
            margin-bottom: 0.25rem;
            padding: 0.3rem 1rem 0.15rem 1rem;
            font-size: 0.95rem;
            font-weight: 500;
            color: $oratioBlue2;
            text-align: center;
            cursor: pointer;
          }
  
          b {
            color: $blackColor;
            font-weight: 400;
          }
    
          .aboutMe_container {
            @include flexRow;
            // position: absolute;
            // top: 11.4rem;
            // left: 8rem;
            position: relative;
            bottom: 2rem;
            width: 100%;
            box-shadow: rgb(0 0 0 / 35%) 0 0.188rem 0.313rem;
    
            input {
              min-height: 2.188rem;
              padding: 0.125rem 0.313rem;
              border: none;
              outline: none;
              width: 81%;
              font-size: 0.85rem;
            }
    
            button {
              color: $whiteColor;
              font-weight: 600;
              border: none;
              padding: 0.5rem 0.65rem;
              font-size: 0.85rem;
              cursor: pointer;
            }

            .cancel {
              background-color: $darkGreyColor;
              margin-right: 0.188rem;
              margin-left: auto;
            }
    
            .save {
              background-color: $greenColor;
              padding: 0.5rem 0.65rem;
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  
    .connection_cats {
      font-size: 0.875rem;
      line-height: 1.2;
      margin: 0 1.563rem;
      border-bottom: 0.031rem solid #cbcbcb;
  
      h3 {
        font-size: 1.125rem;
        margin-top: 0.625rem;
      }
  
      p {
        margin-top: -1rem;
        color: #656667;
        font-size: 0.85rem;
      }
  
      .checkboxes {
        @include flexRow;
        flex-wrap: wrap;
        max-width: 37.5rem;
        margin-bottom: 0.938rem;
  
        div {
          margin-right: 1.875rem;

          input[type="checkbox" i] {
            width: 0.8rem;
            height: 0.8rem;
          }
  
          label {
            margin-top: -0.313rem;
          }
        }
      }
    }
  
    .notification_settings {
      font-size: 0.875rem;
      line-height: 1.2;
      margin: 0 1.563rem;
      border-bottom: 0.031rem solid #cbcbcb;
  
      h3 {
        font-size: 1.125rem;
        margin-top: 0.625rem;
      }
  
      p {
        margin-top: -1rem;
        color: #656667;
        font-size: 0.85rem;
      }
  
      .default_notification {
        @include flexRow;
        justify-content: space-between;
        margin-bottom: 0.938rem;
  
        .cat_label_container {
          cursor: pointer;
          
          .dots {
            height: 0.7rem;
            width: 0.7rem;
            background-color: #3a0c9e;
            border-radius: 50%;
            display: inline-block;
            margin-right: 0.313rem;
          }
        }

        .active {
          background-color: $darkGreyColor;
          color: $whiteColor;
          padding: 0.5rem;
          font-weight: 500;

          .dots {
            background-color: $whiteColor;
          }
        }

        .inactive {
          background-color: transparent;
        }
      }
    }
  
    .notification_channel {
      font-size: 0.875rem;
      line-height: 1.2;
      margin: 0 1.563rem;
      // border-bottom: 0.031rem solid #cbcbcb;
  
      h3 {
        font-size: 1.125rem;
        margin-top: 0.625rem;
      }
  
      p {
        margin-top: -1rem;
        color: #656667;
        font-size: 0.85rem;
      }
  
      .channel_data_inputs {
        margin-bottom: 0.938rem;
        
        div {
          @include flexRow;
          justify-content: flex-start;
          margin-bottom: 0.5rem;
  
          label {
            font-size: 1rem;
            font-weight: 500;
            width: 25%;
          }
  
          input[type='text'],
          input[type='tel'],
          input[type='password'],
          input[type='number'] {
            width: 40%;
            padding: 0.2rem 0.4rem;
            margin-bottom: 0.125rem;
            outline: none;
            border: 0.063rem solid #cbcbcb;
            border-radius: 0.188rem;
          }

          input[type='checkbox'] {
            padding: 0%;
            margin: 0% 0% 0.125rem 0%;
          }

          .input_btns {
            position: absolute;
            left: 66%;
            margin-left: 0.25rem;

            button {
              width: 4rem;
              color: $whiteColor;
              font-size: 1rem;
              font-weight: 600;
              border: none;
              padding: 0.45rem 0.3rem;
              margin: 0 0.1rem;
              transition: all 0.2s;
              cursor: pointer;
            }

            .cancel {
              background-color: $darkGreyColor;
            }

            .save {
              background-color: $greenColor;
            }
          }
        }
      }

      .spryte_email {
        @include flexRow;
        justify-content: space-between;
        max-width: 62%;

        label {
          font-size: 1rem;
          font-weight: 500;
        }

        p {
          margin-top: 0;
          color: #060F26;
          font-weight: 400;
        }

        button {
          width: 4rem;
          background-color: $settingsBtnBgColor;
          color: $oratioBlue2;
          font-size: 0.85rem;
          font-weight: 600;
          border: none;
          // border-radius: 0.313rem;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            transform:scale(1.02, 1.02);
          }
        }
      }
    }

    .logout {
      width: 9rem;
      margin: 4rem 1.5rem 0.625rem auto;
      position: absolute;
      bottom: 0;
      right: 0;

      .logout_btn {
        width: 100%;
        padding: 0.75rem 0.5rem;
        background-color: $blackColor;
        color: $whiteColor;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        transition: all 0.2s;
        cursor: pointer;
    
        &:hover {
          transform:scale(1.02, 1.02);
        }
      }
    }
  }
}

@media #{$media-tablet} {
  .center-div {
    width: 100vw;
    max-width: 100vw;
    height: 95vh;
  }
}

@media #{$media-desktop-plus} {
  .main_settings {
    .notification_settings {
      .default_notification {
        flex-wrap: wrap;
      }
    }

    .notification_channel {
      .channel_data_inputs {
        margin-bottom: 0.938rem;
        
        div {
          @include flexRow;
          justify-content: flex-start;
  
          label {
            width: 32% !important;
          }

          .input_btns{
            left: 73% !important;
          }
        }
      }
    }
  }
}
