::-webkit-scrollbar {
    width: 0.625rem;
    height: 0.438rem;
  }
  
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}