.settings_notification_container {
  position: absolute;
  left: 1.5rem;
  margin-right: 6.5rem;
  margin-top: 1rem;
  
    .settings_notification_modal {
      position: absolute;
      // bottom: 0;
      z-index: 99;
      min-width: 95%;
      width: 22.8rem;
      font-size: 0.9rem;
      font-family: "Manrope", sans-serif;
      color: #000000;
      background-color: #fff;
      border-radius: 0.313rem;
      padding-top: 0%;
      box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
      @include flexRow;
      justify-content: space-between;
  
      .settings_notifications_contents {
        .settings_notification_header {
          @include flexRow;
          justify-content: space-between;
          width: 82%;
          padding: 0 1rem;
          
          h3 {
            color: #3A0C9D;
            font-weight: 600;
          }
        }
  
        .settings_notification_data {
          @include flexRow;
          justify-content: space-between;
          text-align: left;
          border-top: 1px solid #CECECE;
          width: 100%;
          padding: 0 1rem;
  
          &:hover {
            background-color: #ECFAFF;
          }
  
          .frequency {
            margin-right: 7rem;
            width: 3.5rem;

            p {
              font-weight: 500;
              margin-top: 1rem;
              color: #000000;
            }
          }
  
          .freq_description{
            position: relative;
            width: 11rem;
            
            .description {
              position: absolute;
              bottom: 0.3rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #caedf9;
              width: 100%;
              height: 2.5rem;
              box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
            }
          }
  
          .channel {
            margin-top: 0.5rem;
              
            input {
              width: 1rem;
              height: 1rem;
            }
      
            label {
              font-weight: 500;
            }
          }
        }
  
      }
    }
  }
  