.clock_out_resolve_container {
  position: relative;
  bottom: 96%;
  width: 100%;
  height: 74vh;
  z-index: 99;

  .clock_out_modal {
    position: absolute;
    left: 4.5%;
    z-index: 99;
    min-width: 80%;
    max-width: 85%;
    width: 83.5%;
    height: 92%;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.313rem;
    padding: 2rem;
    box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      color: $orangeColor;
      font-size: 1.2rem;
      text-align: center;
    }

    .year_month_day {
      width: 95%;
      padding: 0.75rem 0.25rem;
      margin: 0.75rem;
      cursor: pointer;
      background-color: $blackColor;
      color: $whiteColor;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      // border-radius: 0.5rem;
    }

    .cancel_resolve {
      width: 96%;
      margin: 1.5rem 0.75rem 0.75rem 0.75rem;

      button {
        background-color: #E4E4E4;
        color: #000000;
        border: none;
        padding: 0.75rem 2rem;
        width: 100%;
        // border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .clock_out_resolve_container {
    bottom: 94%;
    height: 90vh;

    .clock_out_modal {
      width: 80%;
      height: 96.5%;
    }
  }
}

@media #{$media-eight-twenty}, #{$media-nine-twelve} {
  .clock_out_resolve_container {
    bottom: 95% !important;
    height: 94% !important;
  }
}

@media #{$media-seven-sixty-eight} {
  .clock_out_resolve_container {
    bottom: 92.5%;
    height: 91%;

    .clock_out_modal {
      width: 85%;
      p {
        font-size: 1.8rem;
      }
    }
  }
}

@media #{$media-five-fourty} {
  .clock_out_resolve_container {
    bottom: 91%;
    height: 88vh;
  }
}

@media #{$media-three-sixty} {
  .clock_out_resolve_container {
    bottom: 58%;
    height: 88vh;
  }
}

@media screen and (width: 1280px) {
  .clock_out_resolve_container {
    bottom: 92%;
    height: 76vh;
  }
}

@media screen and (width: 1024px) {
  .clock_out_resolve_container {
    bottom: 98% !important;
    height: 88.5% !important;
  }
}

@media screen and (height: 812px) {
  .clock_out_resolve_container {
    bottom: 56% !important;
    height: 86% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1040px) {
  .clock_out_resolve_container {
    bottom: 105%;
    height: 91%;
  }
}

@media screen and (height: 1366px) {
  .clock_out_resolve_container {
    bottom: 82% !important;
    height: 95% !important;
  }
}

@media screen and (height: 736px) {
  .clock_out_resolve_container {
    bottom: 98% !important;
    height: 87vh !important;
  }
}

@media screen and (width: 414px) {
  .clock_out_resolve_container {
    bottom: 94.5%;
    height: 90vh;
  }
}

@media screen and (width: 390px) {
  .clock_out_resolve_container {
    bottom: 96%;
  }
}

@media #{$media-three-ninety-three} {
  .clock_out_resolve_container {
    bottom: 95%;
    height: 88%;
  }
}

@media #{$media-three-seventy-five} {
  .clock_out_resolve_container {
    bottom: 60%;
    height: 86%;
  }
}

@media #{$media-mobile-xsm} {
  .clock_out_resolve_container {
    bottom: 86%;
    height: 84vh;

    .clock_out_modal {
      p {
        font-size: 0.9rem;
      }
    }
  }
}
