$duration: 4s;
$text-color: #7e7e7e;

.spinner_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .spinner_container {
    display: inline-block;
    text-align: center;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: #636767;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
  .spinner_phrase_container {
    width: 80vw;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner_phrase {
    font-family: sans-serif;
    font-size: 17px;
    text-align: center;
    background: #222
      linear-gradient(
        -40deg,
        transparent 0%,
        transparent 40%,
        #fff 50%,
        transparent 60%,
        transparent 100%
      )
      0 0 no-repeat;
    background-size: auto;
    color: $text-color;
    background-clip: text;
    animation: shine $duration ease-in-out infinite;
    text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
  }

  @keyframes shine {
    0%,
    10% {
      background-position: -1000px;
    }
    20% {
      background-position: top left;
    }
    70% {
      background-position: top right;
    }
    100% {
      background-position: 1000px;
    }
  }
}
