.group_mem_container {
  height: 5rem;
  border-bottom: 0.063rem solid rgba(216, 216, 216, 0.767);
  border-top: 1px solid $borderColor;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $groupMemBgColor;
  padding: 0.75rem;
  // margin-top: 0.25rem;
  border-left: 1px solid $darkGreyColor;

  h5 {
    margin: 0 0 0 0.125rem;
    padding: 0 0.188rem;
  }

  .group-users-show {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-top: 0.25rem;

    .group-user-single {
      display: flex;
      margin: 0.188rem 0.4rem 0.188rem 0.25rem;

      .remove_member {
        margin-left: auto;
        margin-bottom: 0;
        padding: 0;
        cursor: pointer;
        color: gray;
        line-height: 0.313rem;
        font-weight: bolder;
      }

      .group-user-avatar {
        display: flex;
        margin-right: 0.3rem;

        .group_avatar_image {
          width: 3rem;
          height: 2.9rem;
        }

        .multi_group_avatar_image {
          width: 2rem;
          height: 2rem;
        }
      }

      .group_mem_name,
      .multi_group_mem_name {
        margin: 0%;
        padding: 0%;
        font-weight: 400;
        color: $blackColor;
        align-self: center;
      }

      .group_mem_name {
        font-size: 1rem;
      }

      .multi_group_mem_name {
        font-size: 0.7rem;
      }
    }

    .add_user_container {
      margin-top: 0.813rem;

      .add_btns {
        @include flexRow;
        gap: 0.25rem;
        position: absolute;
        top: 4.5rem;
        right: 1.25rem;

        .add_channel {
          background-color: $blackColor;
          color: $whiteColor;
          border: none;
          font-size: 0.8rem;
          font-weight: 400;
          padding: 0.25rem 0.5rem;
          cursor: pointer;
        }

        .add_user {
          padding: 0 0 0 0.031rem;;
          border: none;
          font-weight: bold;
          width: 1.45rem;
          height: 1.5rem;
          border-radius: 50%;
          color: $lightGreyColor;
          background-color: $blackColor;
          cursor: pointer;
        }

        .options_icon {
          margin-top: -0.25rem;
        }
      }

      .group_search_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55%;
        height: 3.125rem;
        position: absolute;
        top: 5.5rem;
        right: 1.4rem;

        input {
          background-color: #EEF3F7;
          color: #868DA9;
          width: 100%;
          padding: 0.625rem;
          outline: 0;
          font-size: 0.85rem;
          font-weight: 550;
          height: 40%;
          border: 1px solid #B3B3B3;
          border-radius: 0.188rem;
        }

        input::placeholder {
          color: #868DA9;
        }

        .users_search_dropdown {
          position: absolute;
          top: 2.875rem;
          min-width: 100%;
          max-width: 100%;
          max-height: 40rem;
          background-color: #ffffff;
          border-top: 1px grey solid;
          box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
          border-radius: 0.625rem;
          overflow-y: scroll;
          z-index: 2;

          .group_search_results {
            font-size: 0.85rem;
            display: flex;
            border-bottom: 0.063rem solid rgba(216, 216, 216, 0.767) ;
            background-color: #ffffff;
            padding: 1.25rem;

            p {
              padding: 0%; 
              margin: 0% ;
              margin-right: 1.25rem;
            }

            .group_search_results_avatar {
              margin-right: 0.625rem;
              width: 1.875rem;
              height: 1.875rem;
              border-radius: 50%;
            }

            .no_avatar_container {
              margin-right: 0.7rem;
            }

            .add_user_btn {
              width: 5.625rem;
              height: 1.875rem;
              padding: 0.5em 1.5em;
              margin: 0em 0em 0em auto;
              border: 0.16em solid rgb(224, 224, 233);
              background-color: rgb(255, 255, 255);
              border-radius: 2em;
              font-size: smaller;
              box-sizing: border-box;
              text-decoration: none;
              font-family: 'Manrope', sans-serif;
              font-weight: 600;
              color: black;
              text-shadow: 0 0.04em 0.04em rgba(255, 255, 255, 0.253);
              text-align: center;
              transition: all 0.2s;
              position: absolute;
              right: 0;
              cursor: pointer;

              &:hover {
                color: rgb(199, 192, 192);
                background-color: rgb(255, 255, 255);
              }
            }
          }
        }

        .group_no_data_found {
          position: absolute;
          top: 2.875rem;
          right: 0;
          background-color: #ffffff;
          padding: 0.188rem 1.25rem;
          font-size: 1.063rem;
          box-shadow: rgba(0, 0, 0, 0.35) 0 0.313rem 0.938rem;
          z-index: 2;
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .group_mem_container {
    .group-users-show {
      .add_user_container {

        .add_btns {
          top: 7.5rem;
        }

        .group_search_box {
          top: 8.9rem;
        }
      }
    }
  }
}

@media only screen and (width: 1024px) {
  .group_mem_container {
    .group-users-show {
      .add_user_container {

        .add_btns {
          top: 11.25rem;
        }

        .group_search_box {
          top: 12.7rem;
        }
      }
    }
  }
}
