.users-connections {
  width: auto;
  height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;

  .user-card {
    display: flex;
    height: fit-content;
    border-bottom: 1px solid #c0c0c0;
    margin: 0.5rem 1.25rem;

    .user-avatar {
      width: 3.5rem;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 2rem 1rem 0;

      .avatar_image {
        height: 3.5rem;
        width: 3.5rem;
      }
    }

    .info-section {
      padding-top: 0.75rem;
      margin-left: -0.938rem;
      width: 58%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .user-info {
        height: 30%;
        width: 100%;

        .name {
          padding: 0%;
          align-items: center;
          font-size: 1rem;
          font-weight: 700;
          color: $blackColor;
        }

        small {
          font-size: 0.9rem;
          font-weight: 500;
          color: $connTextColor;
          margin-left: 0.5rem;
          overflow: hidden;
        }

        .user_role {
          color: #4960E1;
          font-size: 0.8rem;
          font-weight: 400;
        }

        p {
          margin: 0;
          padding: 0%;
          align-items: center;
          display: flex;
        }
      }

      .user-desc {
        height: 70%;
        width: 147%;

        .bio {
          padding: 0%;
          margin-top: 0.125rem;
          font-size: 1rem;
          font-weight: 400;
          color: $connTextColor;
        }
      }

      .show_btn {
        padding: 0%;
        margin-top: -0.75rem;
        font-size: 0.80rem;
        font-weight: 400;
        color: #7a90f4;
        cursor: pointer;
      }
    }

    .msg_notifs_option_btn {
      @include flexRow;
      position: relative;
      align-items: center;
      width: fit-content;
      height: 100%;
      margin: 0.9rem auto;
      gap: 1.5rem;

      .bn3,
      .disconnect_btn {
        display: inline-block;
        vertical-align: center;
        font-size: 0.84rem;
        box-sizing: border-box;
        text-decoration: none;
        font-family: "Manrope", sans-serif;
        font-weight: 600;
        background-color: $lightGreyColor;
        color: $blackColor;
        text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          color: black;
          background-color: $catsColor;
          transform: scale(1.05, 1.05) ;
        }
      }

      .bn3 {
        width: fit-content;
        padding: 0.7em 2.5em;
      }

      .disconnect_btn {
        padding: 1.2px 0.313rem;
        position: absolute;
        top: 2.4rem;
        right: 0rem;
        font-size: 0.75rem;
      }

      .options {
        display: flex;
        flex-direction: column;
        color: #7a6ae0;
        margin-bottom: -0.4rem;
        cursor: pointer;

        .user-mercado-match {
          margin: auto;
        }
      }

      @media #{$media-desktop-plus} {
        .msg_notifs_option_btn {
          margin: 1em 0 0.1em 0;
        }
      }
    }

    .notifications {

      .notification_btn_container {
        @include flexRow;
        border-radius: 0.313rem;
        font-size: smaller;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Manrope', sans-serif;
        text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
        transition: all 0.2s;
        cursor: pointer;
  
        &:hover {
          color: black;
          background-color: rgb(255, 255, 255);
          transform: scale(1.05, 1.05);
        }
      }

      @media screen and (width: 768px) {
        .notification_btn_container {
          right: 11rem;
        }
      }

      @media screen and (width: 540px) {
        .notification_btn_container {
          right: 9rem;
        }
      }
    }
  }
}

// resizes the width of the scroll bar
.users-connections::-webkit-scrollbar {
  width: 0.3rem;;
}

@media #{$media-desktop-plus} {
  .users-connections {
    height: 100%;

    .user-card {
      .info-section {
        width: 40%;

        .user-desc {
          .bio {
            width: 100%;
          }
        }
      }

      .msg_notifs_option_btn {
        .options {
          .user-mercado-match {
            margin-right: 0.75rem;
          }
        }
      }
    }
  }
}

@media screen and (width: 820px) {
  .users-connections {

    .user-card {
      .info-section {

        .user-info {
          .name {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .users-connections {

    .user-card {
      .info-section {
        width: 35%;

        .user-info {
          .name {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
