.user_status_container {
  position: absolute;
  margin-top: -0.5rem;
  border: 1px solid #B3B3B3;
  background-color: #ffffff;
  width: 30%;
  cursor: pointer;

  p {
    margin: 5%
  }

  .online_status,
  .idle_status,
  .do_not_disturb_status,
  .unavailable_status {
    @include flexRow;
    justify-content: space-between;
    padding: 0 0.75rem;

    &:hover {
      background-color: #EFEEF1;;
    }

    .online,
    .idle,
    .do_not_disturb,
    .unavailable {
      color: #000000;
      font-weight: 400;
    }
  }

  .online_status,
  .idle_status,
  .do_not_disturb_status,
  .unavailable_status {
    .dot {
      height: 0.7rem;
      width: 0.7rem;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .online_status {
    .dot {
      background-color: #07CF1B;
    }
  }

  .idle_status {
    .dot {
      background-color: #FFAD31;
    }
  }

  .do_not_disturb_status {
    .dot {
      background-color: #FF0909;
    }
  }

  .unavailable_status {
    .dot {
      border: 1px solid #858383;
    }
  }
}