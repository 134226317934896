.disc_selectors {
  @include flexRow;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding: 0 0.25rem 0.5rem 0.25rem;
  gap: 0.5rem;

  p {
    padding: 0%;
    margin: 0%;
    font-size: smaller;
    font-weight: 600;
    color: #FFFFFF;
  }

  .my_discussion_active,
  .my_discussion_is_non_admin_active,
  .all_discussion_active,
  .pub_discussion_active,
  .pub_discussion_is_non_admin_active {
    @include flexRow;
    justify-content: center;
    gap: 0.25rem;
    font-weight: 600;
    text-align: center;
    background-color: $chatGroupSelectorBg;
    margin: auto 0;
    transition: all 0.2s;
    box-shadow: 0px 2px 7px rgb(0 0 0 / 8%);
    padding: 0.5rem 0.25rem;
    
    .group_header {
      color: $whiteColor;
      font-size: 12px;
      font-weight: 600
    }
  }

  .my_discussion,
  .my_discussion_is_non_admin,
  .all_discussion,
  .pub_discussion,
  .pub_discussion_is_non_admin {
    @include flexRow;
    justify-content: center;
    gap: 0.25rem;
    font-weight: 600;
    text-align: center;
    background-color: $whiteColor;
    margin: auto 0;
    box-shadow: 0px 2px 7px rgb(0 0 0 / 8%);
    transition: all 0.2s;
    padding: 0.5rem 0.25rem;
    cursor: pointer;
    
    .group_header {
      color: $blackColor;
      font-size: 12px;
      font-weight: 400
    }
  }

  // admin chat selector chips
  .mine,
  .public {
    width: 40%;
  }

  .all {
    width: 18%;
  }

  // non-admin chat selector chips
  .my_discussion_is_non_admin_active,
  .pub_discussion_is_non_admin_active,
  .my_discussion_is_non_admin,
  .pub_discussion_is_non_admin {
    width: 50%;
  }

  .my_disc_counts,
  .all_disc_counts,
  .pub_disc_counts,
  .disc_counts {
    font-size: 0.65rem;
    font-weight: 500;
    width: 1rem;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 1.2rem;
    text-align: center;
    padding: 0 0.4rem;
    margin: auto 0;
    background-color: $orangeColor;
    color: $whiteColor;
  }
}
