.channel_comp_container {
  width: 100%;

  .channels {
    @include flexRow;
    gap: 0.5rem;
    line-height: 2.5rem;

    .channel_data {
  
      p {
        color: $channelTextColor;
        font-size: 11.7123px;
        font-weight: 600;
        margin: 0.5rem 0 0 0;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $blackColor;
          font-weight: 400;
        }

        .channel_last_msg_data {
          font-size: 0.9rem;
          color: $lastMsgTextColor;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .channel_data_active {
      &::before {
        content: "";
        position: absolute;
        left: -0.5rem;
        top: 52%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 8px 5px 0;
        border-color: transparent $blackColor transparent transparent;
      }
    }
  
    .channel_data_inactive {
      cursor: pointer;
    }

    .options_unread_container {
      @include flexRow;
      gap: 0.25rem;
      margin-left: auto;

      .unread_msg {
        width: 1.4rem;
        height: 1.3rem;
        margin: auto 0;
        padding: 0;
        background-color: $cogWheelColor;
        color: $whiteColor;
        font-size: 0.7rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .options_icon {
        width: fit-content;
        height: fit-content;
        margin-bottom: -0.5rem;
        cursor: pointer;
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .channel_comp_container {
    .channels {
      .options_unread_container {
        gap: 1.5rem;
      }
    }
  }
}
