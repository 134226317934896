.modal_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 105%;
  z-index: 98;
  background: rgba(0, 0, 0, 0.5);
}

.confirm_modal {
  position: absolute;
  top: 25%;
  left: 20%;
  right: 20%;
  z-index: 99;
  min-width: 45%;
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 0.313rem;
  padding: 1rem 2rem 2rem 2rem;
  // padding-top: 0%;
  box-shadow: rgb(0 0 0 / 35%) 0px 0.313rem 0.938rem;

  .close_modal {
    margin-left: auto;
    max-width: 1rem;
    color: $danger;
    font-size: $font_size_checkbox;
    font-weight: $bold_font;
    cursor: $pointer;
  }

  .confirm_btn_container {
    margin: 1.5rem auto;

    .confirm_btn,
    .cancel_confirm_btn {
      color: $whiteColor;
      background-color: $blackColor;
      border: none;
      width: 35%;
      padding: 0.7rem;
      // margin: 1rem 0;
      font-size: 1rem;
      font-weight: $bold_font;
      cursor: $pointer;
    }
  
    .cancel_confirm_btn {
      margin-right: 1rem;
    }

    .confirm_btn {
      margin-left: 1rem;
    }
  }
}
