.modal_container {
  position: absolute;
  width: 100%;
  // height: 100%;
  height: 92.5%;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.5);

  .connect_modal {
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 99;
    min-width: 50%;
    max-width: 55%;
    font-size: $font_size_checkbox;
    background-color: $whiteColor;
    border-radius: $five_px_border_rad;
    padding: 2rem;
    padding-top: 0%;
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.313rem 0.938rem;

    .close_modal {
      margin-left: auto;
      max-width: 1rem;
      color: $danger;
      font-size: $font_size_checkbox;
      font-weight: $bold_font;
      cursor: $pointer;
    }

    .checkbox-input {
      margin-left:  0.625rem;
    }

    .checkbox_container {
      @include flexColumn;

      .connect_btn {
        color: $whiteColor;
        background-color: $blackColor;
        // border-radius: 0.313rem;
        border: none;
        width: 35%;
        padding: 0.7rem;
        margin: 1rem auto 1rem 0;
        font-size: $font_size_checkbox;
        font-weight: $bold_font;
        cursor: $pointer;
      }

      .note {
        font-weight: 400;
        color: $connTextColor;
      }

      .note_input {
        width: 95%;
        height: 5rem;
        padding:  0.625rem;
        font-size: 0.85rem;
        outline: none;
        border: 1px solid $borderColor;
        // border-radius:  0.625rem;
      }
    }
  }
}
