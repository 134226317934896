.timePicker_container {
  position: relative;
  bottom: 20%;
  left: 0.5rem;
  width: 100%;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 98;
  }

  .timePicker_modal {
    position: absolute;
    bottom: 20%;
    z-index: 99;
    min-width: 90%;
    max-width: 95%;
    font-size: 0.8rem;
    background-color: $whiteColor;
    border-radius: 0.313rem;
    padding: 1rem;
    padding-top: 4%;
    box-shadow: rgba(0, 0, 0, 0.35) 0 0.313rem 0.938rem;
  }
}

@media screen and (width: 1280px) {
  .timePicker_container {
    left: 3%;
  }
}

@media #{$media-desktop-plus} {
  .timePicker_container {
    left: 0.75rem;
  }
}

@media #{$media-two-eighty} {
  .timePicker_container {
    left: 0;
  }
}

@media #{$media-seven-sixty-eight}, #{$media-nine-twelve} {
  .timePicker_container {
    left: 3%;
  }
}

@media #{$media-five-fourty} {
  .timePicker_container {
    left: 0.4rem;
  }
}

@media #{$media-three-sixty}, #{$media-three-seventy-five} {
  .timePicker_container {
    bottom: 0;
  }
}

