//  example of Colors variables
// $color_prim: #1e96f5;
// $starts: #ffb132;
// $black: #000;
// $grey: rgba(168, 168, 168, 0.856);
// $close: #e74c3c;

// $succes: #6fff6f;
// $sidebare: #f7fafc;
// $text: #1b1f36;
// $color_border: #e2e8ee;

// Font Family
//create the font family you will be using here
$whiteColor: #FFFFFF;
$lightGreyColor: #F5F5F5;
$greyColor: #E0E0E0;
$darkGreyColor: #484848;
$tintedBlackColor: #151515;
$blackColor: #000000;
$orangeColor: #F95E2F;
$navBgColor: #BFBFBF;
$activeChatBgColor: #EEF3F7;
$lightBlueColor: #6867FF;
$oratioBlue: #4960E1;
$oratioBlue2: #4361E9;
$greenColor: #3FDD98;
$catsColor: #A4A4A4;
$chatGroupSelectorBg: #6349FF;
$lastMsgTextColor: #8A8888;
$connTextColor: #57565E;
$groupMemBgColor: #F8F8F8;
$borderColor: #DED9D9;
$greyHover: #EFEEF1;
$checkInBorder: #B7B7B7;
$clockOutFormColor: #9E9E9E;
$clockOutFormBorder: #CCCCCC;
$textColor: #5C5C5C;
$mailBoxBgColor: #FEE5DE;
$settingsBtnBgColor: #DFFFFD;
$settingsTextColor: #115500;
$msgMetaDataColor: #959494;
$channelTextColor: #252525;
$cogWheelColor: #808080;

$danger: #eb3570;
$color_prim: #2d86de;
$five_px_border_rad: 0.313rem;
$font_size_checkbox : 0.8rem;
$bold_font: bold;
$pointer: pointer;
// media queries breakpoints
$media-desktop-plus2: "only screen and (max-width : 1214px)";
$media-desktop-plus: "only screen and (max-width : 1040px)";
$media-desktop: "only screen and (max-width : 1024px)";
$media-landscape: "only screen and (max-width : 870px)";
$media-tablet: "only screen and (max-width : 768px)";
$media-mobile: "only screen and (max-width : 600px)";
$media-mobile-plus: "only screen and (max-width : 540px)";
$media-mobile-sm: "only screen and (max-width : 480px)";
$media-mobile-xsm: "only screen and (max-width : 280px)";
$media-one-zero-two-four: "only screen and (width : 1024px)";
$media-nine-twelve: "only screen and (width : 912px)";
$media-eight-twenty: "only screen and (width : 820px)";
$media-seven-sixty-eight: "only screen and (width : 768px)";
$media-five-fourty: "only screen and (width : 540px)";
$media-three-ninety-three: "only screen and (width : 393px)";
$media-three-ninety: "only screen and (width : 390px)";
$media-three-seventy-five: "only screen and (width : 375px)";
$media-three-sixty: "only screen and (max-width : 360px)";
$media-two-eighty: "only screen and (width : 280px)";
