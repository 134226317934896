.request-center-div {
  display: flex;
  flex-direction: column;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: 'Manrope', sans-serif;

  .request_main {
    background-color: rgb(255, 255, 255);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 1%;

    .req_user_card {
      display: flex;
      height: -moz-fit-content;
      height: fit-content;
      border-bottom: 1px solid #c0c0c0;
      margin: 0 1.25rem;

      .req_user_avatar {
        height: 3.438rem;
        width: 3.438rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 2rem 1rem 0;

        .req_avatar_image {
          height: 3.438rem;
          width: 3.438rem;
          // border-radius: 50%;
        }
      }

      .req_info_section {
        /* padding-top: 0.813rem; */
        margin-left: -0.938rem;
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .req_user_info {
          height: 30%;
          width: 100%;
          margin-bottom: 0.313rem;
          margin-top: -0.2rem;

          .user_req_name {
            margin-bottom: -0.15rem;
            padding: 0%;
            align-items: center;
            font-size: 0.938rem;
            font-weight: 700;
            color: #000000;
          }

          small {
            font-size: 0.8rem;
            font-weight: 500;
            color: $connTextColor;
            overflow: hidden;
          }

          .user_req_role {
            color: $connTextColor;
            margin-left: 0.8rem;
            font-weight: 400;
          }          
        }

        .request_note {
          height: 70%;
          width: 100;

          p {
            padding: 0%;
            padding: 0%;
            margin-top: -0.1rem;
            font-size: 1rem;
            font-weight: 400;
            color: $connTextColor;
          }
        }
      }

      .message-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        height: 100%;
        margin-left: auto;

        .request_btn {
          display: inline-block;
          vertical-align: center;
          width: fit-content;
          padding: 0.6em 2.5em;
          margin: 1.2em -2.5em 0.1em 0;
          // border-radius: 0.313rem;
          font-size: smaller;
          box-sizing: border-box;
          text-decoration: none;
          font-family: "Manrope", sans-serif;
          font-weight: 600;
          background-color: $lightGreyColor;
          color: $blackColor;
          text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
          text-align: center;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            color: black;
            background-color: $catsColor;
            transform: scale(1.05, 1.05) ;
          }
        }

        @media #{$media-desktop} {
          .request_btn {
            margin: 1.5rem -1rem 0.1rem 0;
          }
        }
      }
    }
  }
}

@media #{$media-tablet} {
  .request-center-div {
    width: 100vw;
    max-width: 100vw;
    height: 95vh;
  }
}
