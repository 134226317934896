:root {
  --font-size-small: 0.8127rem; /* 8.20356px */
  --font-size-medium: 0.9836rem; /* 10.9381px */
}

.unit-center-div {
  @include flexColumn;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: "Manrope";
  font-style: normal;

  .unit_main {
    background-color: rgb(255, 255, 255);
    // overflow-y: auto;
    // overflow-x: hidden;
    border-radius: 1%;

    .user-units {
      width: auto;
      height: 100%;

      .message {
        margin-left: 1.8rem;
        font-weight: 600;
        color: red;
      }

      .units-btn {
        display: flex;
        height: -moz-fit-content;
        height: fit-content;
        margin: 1rem 1.25rem;
        flex-direction: row;
        justify-content: end;
        gap: 1.5rem;

        .unit-bn3 {
          box-sizing: border-box;
          text-decoration: none;
          background-color: #f5f5f5;
          text-align: center;
          cursor: pointer;
          padding: 0.7em 2.5em;
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: var(--font-size-small);
          line-height: 0.3125rem; /* converted from 5px or 58% */
          color: #000000;
        }
      }
      .units-stack {
        overflow-x: hidden;
        overflow-y: auto;
        height: 67vh;

        .units-card {
          display: flex;
          height: -moz-fit-content;
          height: fit-content;
          margin: 0.5rem 1.25rem;
          align-items: center;
          border-bottom: 0.341815px solid #e0e0e0;

          .unit-avatar {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-right: 1rem;
          }
          .units > * {
            margin: 0.5rem 0 !important;
          }

          .tech-units {
            display: flex;
            flex-direction: column;
            width: 60%;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 600;
            font-size: var(--font-size-medium);
            color: #000000;
            margin-bottom: 0.5rem;

            .unit-part-interview {
              color: blue;
            }
            .unit-pallen-part-interview {
              width: 30vw;
              margin-top: 0.1rem;
            }
          }

          .units_msg_notifs_option_btn {
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: center;
            width: -moz-fit-content;
            width: fit-content;
            height: 100%;
            margin: 0.5rem auto;
            gap: 1.5rem;

            .bn3 {
              box-sizing: border-box;
              text-decoration: none;
              background-color: #f5f5f5;
              color: #000000;
              text-align: center;
              cursor: pointer;
              padding: 0.7em 2.5em;
              font-family: "Manrope";
              font-style: normal;
              font-weight: 600;
              font-size: var(--font-size-small);
              line-height: 0.3125rem;
            }
          }
        }
      }
      .units-stack::-webkit-scrollbar {
        width: 0.1rem; /* Set the width of the scrollbar */
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .unit-center-div {
    width: 99% !important;
    max-width: 99% !important;
    height: 95vh !important;
    .unit_main {
      height: 100% !important;
    }
  }
}

@media #{$media-three-sixty} {
  .unit-center-div {
    height: 94vh !important;
    .unit_main {
      height: 100% !important;
    }
  }
}

@media #{$media-three-seventy-five} {
  .unit-center-div {
    height: 94vh !important;
    .unit_main {
      height: 100% !important;
    }
  }
}
