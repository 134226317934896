.new_group_btn {
  width: fit-content;
  margin: 1.5rem auto 0 auto;
  position: absolute;
  top: 99.5%;
  width: 49.6%;
  max-width: 49.6%;
  cursor: pointer;

  button {
    background-color: $blackColor;
    color: $whiteColor;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    width: 100%;
    padding: 0.85rem 1rem;
    cursor: pointer;
  }
}

@media #{$media-desktop-plus} {
  .new_group_btn {
    width: 100%;
    top: 91%;

    button {
      width: 100vw;
      padding: 1.8rem 1rem;
      font-size: 1.8rem;
    }
  }
}

@media only screen and (width: 1280px) {
  .new_group_btn {
    top: 98.5%;
    width: 34%;

    button {
      width: 100%;
      padding: 0.85rem 1rem;
      font-size: 1.2rem;
    }
  }
}

@media #{$media-one-zero-two-four} {
  .new_group_btn {
    width: 100%;
    top: 108%;

    button {
      width: 40vw;
    }
  }
}

@media only screen and (width: 768px) {
  .new_group_btn {
    width: 100%;
    top: 91%;

    button {
      width: 100vw;
      padding: 1.9rem 1rem;
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1040px) {
  .new_group_btn {
    width: 100%;
    // top: 108%;
    top: 100%;

    button {
      width: 40vw;
    }
  }
}

@media #{$media-eight-twenty}, #{$media-nine-twelve} {
  .new_group_btn {
    width: 100%;
    // top: 103.5%;
    top: 97%;

    button {
      width: 40vw;
      padding: 2rem 1rem;
      font-size: 2rem;
    }
  }
}

@media #{$media-five-fourty} {
  .new_group_btn {
    width: 100%;
    top: 92%;

    button {
      width: 100vw;
      padding: 1.2rem 1rem;
      font-size: 1.2rem;
    }
  }
}

@media #{$media-three-sixty} {
  .new_group_btn {
    width: 100%;
    top: 91%;

    button {
      width: 100vw;
      padding: 1.6rem 1rem;
      font-size: 1.4rem;
    }
  }
}

@media #{$media-two-eighty}, #{$media-three-seventy-five} {
  .new_group_btn {
    width: 100%;
    top: 90%;

    button {
      width: 100vw;
      padding: 1.3rem 1rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen and (height: 1366px) {
  .new_group_btn {
    width: 100%;
    top: 98%;

    button {
      width: 40vw;
      padding: 1.8rem 1rem;
      font-size: 2rem;
    }
  }
}

@media only screen and (height: 812px) {
  .new_group_btn {
    width: 100%;
    top: 92%;

    button {
      width: 100vw;
    }
  }
}

@media only screen and (height: 740px) {
  .new_group_btn {
    width: 100%;
    top: 91%;

    button {
      width: 100vw;
      padding: 1.4rem 1rem;
      font-size: 1.4rem;
    }
  }
}
