.reset_pass_back {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .reset_pass_modal {
    position: absolute;
    top: 25%;
    margin: 0 15px;
    z-index: 101;
    width: 93%;
    font-size: 0.8rem;
    background-color: #fbf7f7;
    border-radius: 0.313rem;
    padding: 2rem;
    // padding-top: 0%;
    box-shadow: rgb(0 0 0 / 35%) 0px 0.313rem 0.938rem;
  
    .close_modal {
      position: absolute;
      top: 13%;
      left: 90%;
      right: 10%;
      max-width: 1rem;
      color: #000000;
      font-size: 0.7rem;
      font-weight: bold;
      cursor: pointer;
    }
  
    .reset_pass_header {
      h2 {
        font-size: 18px;
        font-weight: 700;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        color: gray
      }
    }
  
    .input_container {
      @include flexColumn;
      width: 100%;
      padding: 0.5rem 0;
      margin: 0.5rem 0;
  
      label {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }
  
      input {
        height: 2.188rem;
        font-size: 1.2rem;
        border-radius: 0.313rem;
        outline: none;
        border: 1px solid rgb(204, 204, 204);
        padding: 0.313rem 0.625rem;
      }
  
      p {
        color: #eb3570;
      }
    }
  
    .reset_pass_btns {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
  
      .reset_btn,
      .cancel_reset_btn {
        color: #fff;
        background-color: #4CC4EB;
        border-radius: 12px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: bold;
        text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;
  
        &:hover {
          background-color: #1d70c4;
          transform:scale(1.03,1.03);
        }
      }
    }
  }
}

