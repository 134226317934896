.show_midnight_form_container {
  position: relative;
  bottom: 94.9%;
  width: 100%;
  height: 74vh;
  z-index: 99;
  
  .show_midnight_modal {
    position: absolute;
    z-index: 99;
    min-width: 85%;
    max-width: 93%;
    width: 92.5%;
    height: 98.6%;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.313rem;
    padding: 2rem;
    box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      font-size: 1.3rem;
      text-align: center;
      font-weight: 500;

      &:first-child {
        color: #4960E1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        color: #000000;
      }
    }

    .understand_btn_container {
      margin: 3rem auto;
      max-width: 16rem;

      button {
        background-color: #FFFFFF;
        color: #4960E1;
        border: 1px solid #EFEFEF;
        padding: 1.2rem 3.5rem;
        font-weight: 600 !important;
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .show_midnight_form_container {
    bottom: 94%;
    height: 91vh;

    .show_midnight_modal {
      width: 90%;
    }
  }
}

@media screen and (width: 1280px) {
  .show_midnight_form_container {
    bottom: 90%;
    height: 76vh;

    .show_midnight_modal {
      width: 92%;
    }
  }
}

@media screen and (width: 1024px) {
  .show_midnight_form_container {
    bottom: 102%;
    height: 73vh;

    .show_midnight_modal {
      width: 89.5%;
    }
  }
}

@media #{$media-nine-twelve} {
  .show_midnight_form_container {
    bottom: 98%;
    height: 78vh;

    .show_midnight_modal {
      width: 88.5%;
    }
  }
}

@media #{$media-eight-twenty} {
  .show_midnight_form_container {
    bottom: 99%;
    height: 77vh;

    .show_midnight_modal {
      width: 88%;
    }
  }
}

@media #{$media-seven-sixty-eight} {
  .show_midnight_form_container {
    bottom: 91.5% !important;
    height: 91.5vh;

    .show_midnight_modal {
      max-width: 94%;
      width: 97% !important;
    }
  }
}

@media #{$media-five-fourty} {
  .show_midnight_form_container {
    bottom: 94% !important;
    height: 89vh;

    .show_midnight_modal {
      width: 94% !important;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 800px) {
  .show_midnight_form_container {
    bottom: 100%;
    height: 91vh;

    .show_midnight_modal {
      width: 91%;
    }
  }
}

@media screen and (width: 414px) {
  .show_midnight_form_container {
    bottom: 94%;
    height: 90vh;

    .show_midnight_modal {
      width: 90%;
    }
  }
}

@media #{$media-three-ninety-three} {
  .show_midnight_form_container {
    bottom: 95.5%;
    height: 91vh;

    .show_midnight_modal {
      width: 89.5%;
    }
  }
}

@media #{$media-three-seventy-five} {
  .show_midnight_form_container {
    bottom: 95%;
    height: 88vh;

    .show_midnight_modal {
      width: 89%;
    }
  }
}

@media #{$media-three-sixty} {
  .show_midnight_form_container {
    bottom: 91%;
    height: 90vh;

    .show_midnight_modal {
      width: 89.5% !important;
    }
  }
}

@media #{$media-three-ninety} {
  .show_midnight_form_container {
    bottom: 95%;
    height: 90vh;

    .show_midnight_modal {
      width: 89.5%;
    }
  }
}

@media #{$media-two-eighty} {
  .show_midnight_form_container {
    bottom: 90.5%;
    height: 87vh;

    .show_midnight_modal {
      width: 80%;
    }
  }
}

@media screen and (height: 1366px) {
  .show_midnight_form_container {
    bottom: 84%;
    height: 77.5vh;

    .show_midnight_modal {
      width: 89.5%;
    }
  }
}

@media screen and (height: 812px) {
  .show_midnight_form_container {
    bottom: 90%;
    height: 90vh;

    .show_midnight_modal {
      width: 89%;
    }
  }
}

@media screen and (height: 740px) {
  .show_midnight_form_container {
    bottom: 92.5%;
    height: 89vh;

    .show_midnight_modal {
      width: 88%;
    }
  }
}

@media screen and (height: 736px) {
  .show_midnight_form_container {
    bottom: 98%;
    height: 89vh;

    .show_midnight_modal {
      width: 90%;
    }
  }
}
