.feedback-chat {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
  position: relative;
  /* Set position to relative */

  .question {
    // margin: 2rem 1rem;
    // font-family: 'Manrope';
    font-weight: bolder;
    font-size: 20px;
    line-height: 1.5;
    color: white;
  }

  .feedback-message-chat {
    flex: 1;
    overflow-y: auto;
    // padding: 1rem;
    margin: 4rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    /* Set position to relative */
    padding-bottom: 100px;
    /* Add bottom padding to accommodate like/dislike buttons */

    .feedback-chat-section {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;

      .circle {
        flex-shrink: 0;

        .avatar-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .avatar-initials {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: lightblue;
          display: flex;
          justify-content: center;
          align-items: center;
        }


      }

      .message-container,
      .message-container-feedback {
        flex: 1;

        .message-sender {
          margin-top: 0.5rem;
          font-size: 18px;
        }

        .likedislike {
          margin-top: auto;
          margin-bottom: 1rem;
          display: flex;
          justify-content: flex-start;
        }

        .likedislike button {
          margin-top: 1rem;
          margin-right: 1rem;
        }

        .message {
          margin: 0.5rem 0;
          font-size: 16px;
          line-height: 1.5;
          color: #afafaf;

          &.long-question-feedback {
            font-size: 14px;
          }

          .rating {
            font-size: 18px;
            margin-left: 1rem;
          }
        }

        .evaluator-message {
          font-size: 18px;
          font-weight: 600;
        }

        .avatar-rating-container {
          display: flex;
          align-items: center;
        }

        .circle {
          flex-shrink: 0;
        }

        .avatar-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .rating {
          color: #afafaf;
          font-weight: bolder;
          font-size: 18px;
          margin-left: 1rem;
        }
      }
    }
  }



  .continue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);

    button {
      flex: 1;
      margin: 0.5rem;
      font-size: 16px;
      padding: 0.75rem;
    }
  }
}

.feedback-close-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  background: grey;
  opacity: 0.9;
  border-radius: 30px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
}

@media (min-width: 640px) {
  .feedback-chat{
    max-width: 672px;
    // margin: 0 auto;
  }
}