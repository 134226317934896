.welcome-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;

    @media (min-width: 640px) {
        max-width: 672px;
        margin: 0 auto;
      }
}

.top-overlay {
    opacity: 0.5;
    background: white;
    height: 50vh;
}

.bottom-modal-container {
    background: #4d4d4d;
    height: 55vh;

    .modal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        align-items: center;
        gap: 20px;
        padding: 40px;

        .logo {
            height: 7rem;
            width: 7rem;
        }

        .centered-white-text {
            text-align: center;
            color: white;
        }
    }
}

.begin-btn {
    flex: 1 1 96%;
    box-shadow: 0 2px 0 rgb(88, 167, 0), 0 0 0 rgb(24, 153, 214);
    width: 80vw;
}