.checkin {
  @include flexColumn;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: 'Manrope', sans-serif;

  .checkin-container {
    background-color: rgb(255, 255, 255);
    border-radius: 1%;
    height: 80vh;

    .checkin-welcome {
      margin: 1.125rem 1.125rem 2rem 1.125rem;

      .welcome {
        font-size: 1.2rem;
        color: #000000;
        font-weight: 400
      }

      .resolve {
        color: $whiteColor;
        background-color: $orangeColor;
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        // border-radius: 0.3rem;
        display: inline-block;
        width: 100%;
        padding: 0.55rem 0;
        cursor: pointer;
      }

      .checkin_btn_container {
        width: 100%;
        margin: 0.75rem auto 1rem auto;
        display: flex;
        justify-content: center;

        .checkin-button {
          border: none;
          padding: 1.8rem 2rem;
          text-align: center;
          text-decoration: none;
          @include flexRow;
          justify-content: center;
          align-items: center;
          font-size: 0.8rem;
          // border-radius: 0.4rem;
          cursor: pointer;
        }

        .clockIn {
          background-color: $greyColor;
          width: 100%;

          b {
            margin-left: 1rem;
            font-size: 1.2rem;
            color: $blackColor;
            font-weight: 700;
          }
        }

        .clockOut {
          background-color: $greyColor;
          width: 49%;
          margin-right: 0.5rem;

          b {
            margin-left: 1rem;
            font-size: 1.2rem;
            color: $blackColor;
            font-weight: 700;
          }
        }

        .pause,
        .resume {
          background-color: $whiteColor;
          width: 49%;
          margin-left: 0.5rem;
          border: 1px solid $checkInBorder;

          b {
            margin-left: 1.2rem;
            font-size: 1rem;
            color: $oratioBlue;
            font-weight: 700;
          }
        }
      }
      .checkin-item-parent-container {
        height: 56vh;
        overflow-x: hidden;
        overflow-y: auto;

        .checkin-item-container {
          border: 1px solid #b7b7b7;
          margin-bottom: 1rem;

          .checkin-item {
            width: 100%;

            .checkin-timesheet {
              margin-left: 20px;
              margin-right: 20px;

              .checkin-title {
                @include flexRow;
                justify-content: space-between;
                font-weight: 700;
                padding: 1rem;
              border-bottom: 1px solid #B7B7B7;
                cursor: pointer;

                &:hover {
                  background-color: rgb(255, 255, 255);
                }

                .involvement,
                .worked {
                  @include flexRow;

                  b {
                    margin-left: 0.5rem;
                    font-size: 1rem;
                    font-weight: 700;
                  }
                }

                .involvement {
                  margin-right: auto;

                  b {
                    color: #000000;
                  }
                }

                .worked {
                  margin-left: auto;
                  margin-right: 0.5rem;

                  b {
                  color: #4960E1;
                  }
                }

                .accordion_arrows {
                color: #4960E1;
                }
              }

              .checkin-presence-data {
                @include flexRow;
              border-bottom: 1px solid #B7B7B7;
                font-size: 1.4rem;
                font-weight: 700;
                cursor: pointer;

                .header {
                  width: 33%;
                  padding: 1rem;
                }

              .next_date ,
                .prev_date,
                .current_date {
                color: #A5A6A8;
                }

                .current_date {
                  text-align: center;
                }

                .prev_date {
                  text-align: left;
                }

                .next_date {
                  text-align: right;
                }

                .active_date {
                  color: $oratioBlue2;
                }
              }

              .checkin-content {
                background-color: rgb(255, 255, 255);

                .checkin-content-header {
                  @include flexRow;

                  .header {
                    width: 33%;
                    padding: 1rem;
                  }

                  .header_type {
                    text-align: left;
                  }

                  .header_time {
                    text-align: center;
                  }

                  .header_access {
                    text-align: right;
                  }
                }

                .checkin-content-data {
                  @include flexRow;
                  margin: 0 1rem;

                  .child {
                    width: 33%;
                    padding: 1rem 0;
                    text-align: right;
                  }

                  .edit_btn {
                    cursor: pointer;
                  }

                  .font-green {
                    color: $oratioBlue2;
                    font-size: 1.2rem;
                    font-weight: 700;
                  }

                  .uppercase {
                    text-transform: uppercase;
                    text-align: left;
                  }

                  .time {
                    font-weight: bold;
                    text-align: center;
                  }

                  .edit {
                    color: $oratioBlue2;
                    text-align: right;
                    font-size: 1.2rem;
                    font-weight: 700;
                    cursor: pointer;
                  }
                }

                .empty_timesheet {
                  @include flexRow;
                  justify-content: space-between;

                  p {
                    font-size: 1.2rem;
                    font-weight: 700;

                    &:first-child,
                    &:nth-child(2) {
                      color: #000000;
                    }

                    &:nth-child(3) {
                      color: $oratioBlue2;
                    }
                  }
                }

                .checkin-content-report {
                  @include flexColumn;
                  width: 100%;
                  margin-bottom: 0.5rem;

                  .checkin-button {
                    border: none;
                    padding: 1.4rem 2rem;
                    text-align: center;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 1rem;
                    width: 100%;
                    cursor: pointer;

                    span {
                      color: #000000;
                    }

                    .first_child {
                      width: 25%;
                      text-align: left;

                      b {
                        font-weight: 700;
                      }
                    }

                    .border {
                      color: $whiteColor;
                    }
                  }

                  .week {
                    background-color: $lightGreyColor;
                    margin-bottom: 0.5rem;

                    span {
                      &:first-child b {
                        font-weight: 400;
                      }
                    }
                  }

                  .month {
                    background-color: $greyColor;
                    margin-bottom: 0.5rem;
                  }

                  .all-time {
                    background-color: $darkGreyColor;
                    margin-bottom: 0.5rem;
                  }
                }
              }
            }
          }
        }

        .colleagues-container {
          border: 1px solid #9fadff;
          margin-bottom: 0.5rem;

          .colleagues-item {
            margin-left: 20px;
            margin-right: 18px;
            justify-content: space-between;

            .colleagues-accordion {

              display: flex;
              align-items: center;
              font-size: 1.2rem;
              //margin: 10px 0;
              justify-content: space-between;


              .colleagues{

                display: flex;
                align-items: center;
                margin: 10px 0;

              }
              
             
            }

            .accordion_arrows {
              color: #4960e1;
              //align-self: right;
            }

            .whatever {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #f5f5f5;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
            .colleagues-contents {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 20px;
            }

            .colleague {
              display: grid;
              grid-template-rows: auto 1fr;
              border: 1px solid #9fadff;
              padding: 10px;
              width: 100%;
            }

            .avatar-container {
              width: 50px;
              height: 50px;
              border-radius: 4px;
              border-width: 2px;
              border-style: solid;
              overflow: hidden;
              margin-right: 10px;
            }

            .avatar-container img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .details {
              margin-right: 10px;
              min-height: 54px;

              h3,
              p {
                margin: 0%;
              }
            }
            .review {
              max-width: 50px;
              padding: 0 15px;
              margin-left: auto;
              text-align: center;
              background-color: #f5f5f5;
              margin-right: 10px;
              p {
                font-weight: 600;
              }
              &:hover {
                background-color: #d9d9d9;
              }
            }
            .legend-item {
              display: flex;
              flex-direction: column;
            }

            .legends {
              display: flex;
              margin-bottom: 0.5rem;
            }
            .legend-text {
              margin-left: 5px;
            }
            .legend-box {
              display: flex;
              align-items: center;
              margin-right: 20px;
            }
          }

          .loading {

            padding: 1rem;
            font-size: 1.2rem;
            font-weight: 600;

          }
        }

        .no-colleagues {

          padding: 1rem;
          font-size: 1.2rem;
          font-weight: 600;
          
        }
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .checkin {
    .checkin-container {
      height: 100%;

      .checkin-welcome {
        .welcome {
          font-size: 0.95rem;
        }

        .resolve {
          font-size: 1.5rem;
        }

        .checkin-item-parent-container {
          // height: 70vh;
          height: 56vh;
        }
      }
    }
  }
}

// mui clock custom styles
.css-1jm66jt-MuiClockPicker-root {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  width: 90% !important;
  max-height: 95% !important;

  .css-d0vs79-MuiClockPointer-root {
    height: 85px !important;

    .css-eg3pzz-MuiClockPointer-thumb {
      width: 2px !important;
      height: 2px !important;
    }
  }
}

.css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
  margin: 0 auto;

  .css-1hbyad5-MuiTypography-root {
    align-self: center;
  }

  .css-pbg3za-MuiGrid-root-MuiPickersToolbar-content {
    justify-content: center;
  }
}

@media #{$media-tablet} {
  .checkin {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;

    .checkin-container {
      .checkin-welcome {
        .checkin-item-parent-container {
          height: 80vh;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .checkin {
    width: 53vw;
    max-width: 53vw;
  }
}

@media only screen and (width: 912px) {
  .checkin {
    .checkin-container {
      .checkin-welcome {
        .checkin-item-parent-container {
          height: 67vh !important;
        }
      }
    }
  }
}

@media #{$media-nine-twelve}, #{$media-eight-twenty} {
  .checkin {
    width: 53vw;
    max-width: 53vw;

    .checkin-container {
      .checkin-welcome {
        .checkin-item-parent-container {
          height: 65vh;
        }
      }
    }
  }

  .css-1jm66jt-MuiClockPicker-root {
    .css-1j9v0by-MuiClock-root {
      margin: 0;
      // margin-right: auto;
    }
  }

  .css-j3g793-MuiCalendarOrClockPicker-root {
    flex-direction: column !important;

    .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
      // justify-content: flex-start;

      button {
        &:first-child {
          margin-right: 2.7rem;
        }
      }
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      width: 80%;
    }
  }
}

@media only screen and (width: 540px) {
  .checkin {
    .checkin-container {
      .checkin-welcome {
        .checkin-item-parent-container {
          height: 72vh;
        }
      }
    }
  }

  .css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
    margin: 0 auto;

    .css-1hbyad5-MuiTypography-root {
      align-self: center;
    }

    .css-pbg3za-MuiGrid-root-MuiPickersToolbar-content {
      justify-content: center;
    }
  }
}

@media #{$media-mobile-sm} {
  .css-1jm66jt-MuiClockPicker-root {
    .css-1j9v0by-MuiClock-root {
      margin: 0;
      // margin-right: auto;
    }
  }

  .css-j3g793-MuiCalendarOrClockPicker-root {
    flex-direction: column !important;

    .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
      // justify-content: flex-start;

      button {
        &:first-child {
          margin-right: 2.7rem;
        }
      }
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 375px) {
  .checkin {
    .checkin-container {
      .checkin-welcome {
        .checkin-item-parent-container {
          height: 40vh;
        }
      }
    }
  }
}

@media only screen and (max-width: 280px) {
  .checkin {
    .checkin-container {
      .checkin-welcome {
        .resolve {
          font-size: 1.2rem;
        }

        .checkin-item-parent-container {
          height: 65vh;
        }
      }
    }
  }

  .css-1jm66jt-MuiClockPicker-root {
    .css-1j9v0by-MuiClock-root {
      margin: 0;
      margin-right: auto;
    }
  }

  .css-j3g793-MuiCalendarOrClockPicker-root {
    flex-direction: column !important;

    .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
      // justify-content: flex-start;

      button {
        &:first-child {
          margin-right: 2.7rem;
        }
      }
    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      width: 80%;
    }

    .css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
      padding: 0;

      .css-1hbyad5-MuiTypography-root {
        align-self: flex-start;
        margin-left: 1.4rem;
      }

      .css-pbg3za-MuiGrid-root-MuiPickersToolbar-content {
        justify-content: flex-start;

        .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
          justify-content: flex-start;
        }
      }
    }
  }
}
