.App {
  display: flex;
  flex-direction: column;
  // width: 40vw;
  // max-width: 40vw;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: "Manrope", sans-serif;
  border-radius: 1%;
  position: fixed;
  bottom: 9rem;
  right: 3rem;
}

.App-Nestria {
  // display: flex;
  // flex-direction: column;
  // width: 40vw;
  // max-width: 40vw;
  width: 100%;
  max-width: 100%;
  height: 100%;
  font-family: din-round, sans-serif;
  border-radius: 1%;
  // position: fixed;
  bottom: 9rem;
  right: 3rem;
}

.delete-icon {
  width: 15px !important;
  height: 15px !important;
}

#speechinput {
  width: 25px; // just wide enough to show mic icon
  height: 25px; // just high enough to show mic icon
  cursor: pointer;
  border: none;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

[data-recording='true'] {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(218, 18, 18);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
  }
}

@media #{$media-tablet} {
  .App {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    right: 0;
    left: 0;
    top: 0.8rem;
    bottom: 0;
  }
}