.remove_modal {
    position: absolute;
    top: 20%;
    left: 41%;
    z-index: 99;
    min-width: 45%;
    max-width: 50%;
    font-size: 0.8rem;
    background-color: #fff;
    border-radius: 0.313rem;
    padding: 2rem;
    padding-top: 0%;
    box-shadow: rgb(0 0 0 / 35%) 0 0.313rem 0.938rem;
  
    .confirm_remove {
      max-width: 100%;
      max-height: 50%;
    }
  
    .close_modal {
      margin-left: auto;
      max-width: 1rem;
      color: #eb3570;
      font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
    }
  
    .confirm_btn_container {
      margin: 0 auto;
      max-width: 50%;
      
      .confirm_btn,
      .cancel_confirm_btn {
        color: #fff;
        background-color: #2d86de;
        border-radius: 5%;
        border: none;
        width: 45%;
        padding: 0.4rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
      }
  
      .cancel_confirm_btn {
        margin-right: 0.5rem;
      }
    }
  }
  
  @media #{$media-desktop-plus} {
    .del_modal {
      left: 22%;
    }
  }
  