:root {
  --font-family: "Manrope", sans-serif; /* 8.20356px */
  --font-style: normal; /* 10.9381px */
}
.create-unit-container {
  width: 97.9%;
  height: 95%;
  background-color: $whiteColor;
  position: absolute;
  padding: 0 0.4rem;
  z-index: 100000;

  .back_btn {
    align-self: left;
    width: fit-content;
    @include flexRow;
    gap: 0.2rem;
    //margin-bottom: 1rem;
    color: #000000;
    //margin-top: 0.5rem;
    padding: 0.5rem 0;
    p {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      font-size: 0.871rem;
      line-height: 0.308rem;
      margin: 0;
      /* or 39% */

      display: flex;
      align-items: center;

      color: #000000;
    }
  }

  .create-unit-header {
    @include flexRow;
    justify-content: space-between;
    //font-weight: 400;
    margin-top: 0.8rem;

    .header {
      @include flexRow;
      margin-bottom: 0.5rem;
      gap: 0.3rem;

      label {
        margin: 0;
        color: $blackColor;
        font-weight: 600;
        font-size: 12.989px;
      }
    }

    .toggle_switch_container {
      @include flexRow;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      align-items: center;

      p {
        margin: 0;
        font-size: 0.769rem;
        line-height: 0.3125rem;
        font-weight: 400;

        &:first-child {
          color: #8a8888;
        }

        &:nth-child(2) {
          color: $blackColor;
        }
      }

      .toggle_switch {
        position: relative;
        bottom: 0.1rem;
        display: inline-block;
        width: 1.986875rem;

        height: 1.068125rem;
        background-color: #f5f5f5;
        border-radius: 1.7rem;
        cursor: pointer;

        .toggle_handle {
          position: absolute;
          top: 1.9px;
          right: 1.8px;
          width: 1rem;
          height: 1rem;
          background-color: #000000;
          border-radius: 50%;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
          transition: transform 0.2s ease;
        }
      }

      .toggle_switch.on {
        background-color: $greenColor;

        .toggle_handle {
          transform: translateX(-20px);
        }
      }

      .toggle_switch.off {
        background-color: #d9d9d9;
      }
    }
  }

  .unit-container,
  .unit-desc-container,
  .unit-admin-notes-container {
    display: flex;
    flex-direction: column;

    label {
      margin: 0;
      color: #000000;
      font-weight: 600;
      font-size: 12.3053px;
    }

    .unit_name,
    .admin_notes {
      color: rgb(138, 136, 136);
      border-width: 1px;
      border-style: solid;
      border-color: rgb(224, 224, 224);
      border-image: initial;
    }

    .unit-desc {
      color: rgb(138, 136, 136);
      border-width: 1px;
      border-style: solid;
      border-color: 0.341815px solid #e4e4e4;
      border-image: initial;
      resize: vertical; /* Allow vertical resizing */
      min-height: 4rem; /* Set a minimum height for the text areas */
      max-height: 10rem; /* Set a maximum height for the text areas */
      overflow-y: auto; /* Add vertical scrollbars if the content exceeds the maximum height */
    }

    .unit_name {
      //height: 2rem;
      font-weight: 400;
      font-size: 10.9381px;
      padding: 0.2rem; /* Add padding to adjust text position */
      text-align: left; /* Align text to the left */
      white-space: normal;
    }

    .unit-desc {
      //height: 8.938rem; /* 143px / 16px */
      font-weight: 400;
      font-size: 10.9381px;
      background: #f8f8f8;
      border: 0.341815px solid #e4e4e4;
    }

    .unit-error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }

    .admin_notes {
      height: 3.1275rem; /* 82.04px / 16px */
      background-color: #f8f8f8;
      text-align: left;
      font-weight: 400;
      font-size: 12.989px;
    }
  }

  .unit-container {
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  .unit-container,
  .unit-desc-container {
    margin-bottom: 0.2rem;
  }

  .unit-admin-notes-container {
    margin-bottom: 0.3rem;
  }

  .unit-text {
    margin: 0;
    color: $blackColor;
    font-weight: normal;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .include {
    margin: 0;
    color: #000000;
    font-weight: 600;
    font-size: 12.3053px;
  }

  .search_box_container {
    margin: 0.5;
  }

  .assigned-unit-ques-list,
  .unit-ques-list {
    overflow-y: auto;
    overflow-x: hidden;

    .unit-ques {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem;

      .list-ques {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        line-height: 1.2rem;

        .ques-text {
          font-family: "Manrope, sans-serif";
          font-style: normal;
          color: #000000;
          font-weight: 400;
          font-size: 12.3308px;
        }
      }

      .xp-add-rem {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin: 0;
        padding: 0;

        .question-xp {
          width: 2rem;
          height: 2rem;
          padding: 0;
          font-weight: 400;
          font-size: 12.989px;
          border: 1px solid $blackColor;
        }
      }
    }

    .unit-error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .assigned-unit-ques-list::-webkit-scrollbar,
  .unit-ques-list::-webkit-scrollbar {
    width: 0.1rem; /* Set the width of the scrollbar */
  }
  .assigned-unit-ques-list,
  .assigned-course-unit-list.empty {
    height: 8rem;
    /* margin: 2rem 1rem; */
    padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
  }

  .unit-ques-list {
    padding: 0.5rem 0.5rem;
    height: 10rem;
    margin-bottom: 0.5rem;
  }
  .delete-ques-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 0.1rem;

    p {
      margin: 0.25rem 0;
      padding: 0;
      text-align: right;
      color: #ff223d;
      font-weight: 400;
      font-size: 12.989px;
      cursor: pointer;
    }
  }

  .create_save_btn {
    width: 100%;

    .button {
      display: flex; /* Added */
      align-items: center; /* Added */
      justify-content: center; /* Added */
      padding: 1rem 2rem;
      background-color: $blackColor;
      color: white;
      height: 1.2rem;
      text-align: center;
      cursor: pointer;
    }
  }
}
