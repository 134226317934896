.companies-connections {
  width: auto;
  height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;

  .comp-card {
    @include flexRow;
    height: fit-content;
    border-bottom: 0.063rem solid #c0c0c0;
    margin: 0 1.25rem;

    .comp-avatar {
      @include flexRow;
      justify-content: center;
      width: 8%;
      height: 100%;
      align-items: center;
      margin-top: 1.25rem;
      margin-right: 2rem;

      .avatar_image {
        height: 3.438rem;
        width: 3.438rem;
        border-radius: 50%;
      }
    }

    .info-section {
      padding-top: 0.813rem;
      width: 60%;
      height: 100%;
      @include flexColumn;

      .comp-info {
        height: 30%;
        width: 100%;
        margin-bottom: 0.313rem;

        .title {
          margin-bottom: -0.5rem;
          padding: 0%;
          align-items: center;
          font-size: 0.938rem;
          font-weight: 700;
          color: #6f6f6f;
        }

        small {
          font-size:  0.625rem;
          font-weight: 500;
          color: #6f6f6f;
          overflow: hidden;
        }

        p {
          margin: 0;
          padding: 0%;
          align-items: center;
          font-size:  0.625rem;
          font-weight: 700;
          color: #6f6f6f;
        }

        .motto {
          margin: 0;
          padding: 0%;
          color: $oratioBlue2;
          align-items: center;
          font-size: 0.85rem !important;
          font-weight: 400;
        }
      }

      .comp-desc {
        height: 70%;
        width: 100;

        p {
          padding: 0%;
          margin-top: -0.125rem;
          font-size: 0.80rem;
          font-weight: 400;
          color: $textColor;
        }
      }
    }

    .message-btn {
      @include flexColumn;
      align-items: center;
      width: 20%;
      height: 100%;
      margin-left: auto;

      .comp_options {
        @include flexColumn;
        position: absolute;
        position: relative;
        left: 5rem;
        bottom: 2.35rem;
        color: #7a6ae0;
        cursor: pointer;
      }

      .bn3,
      .disconnect_btn {
        display: inline-block;
        vertical-align: center;
        border: 0.16em solid rgb(129, 122, 223);
        border-radius: 0.313rem;
        font-size: smaller;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 300;
        color: #7a6ae0;
        text-shadow: 0 0.04em 0.04em rgb(255 255 255 / 25%);
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;
      }
      
      .bn3 {
        width: 6.25rem;
        padding: 0.3rem 1.5rem;
        margin: 1.5rem -3rem 0.1rem 0;

        &:hover {
          color: black;
          background-color: rgb(255, 255, 255);
          transform:scale(1.03,1.03);
        }
      }

      @media #{$media-desktop} {
        .bn3 {
          display: block;
          margin: 1.5rem -1rem 0.1rem 0;
        }

        .comp_options {
          left: 4rem;
        }
      }
      
      .disconnect_btn {
        position: absolute;
        right: 1rem;
        top: 2.3rem;
        padding: 0.063rem 0.313rem;
        margin: 0 0.188rem 0.188rem 0;
        font-size: 0.75rem;
      }
    }
  }
}

// resizes the width of the scroll bar
.companies-connections::-webkit-scrollbar {
  width: 0.3rem;;
}
  
.mercado-match {
  margin: auto;
}

@media #{$media-desktop-plus} {
  .companies-connections {
    height: 80vh;
  }
}
