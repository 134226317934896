:root {
  --font-size-small: 0.8127rem; /* 8.20356px */
  --font-size-medium: 0.9836rem; /* 10.9381px */
  --line-height-medium: 1rem; /* 16px or 150% */
}

.ques-center-div {
  @include flexColumn;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: "Manrope";
  font-style: normal;

  .ques_main {
    background-color: rgb(255, 255, 255);
    // overflow-y: auto;
    // overflow-x: hidden;
    border-radius: 1%;

    .user-questions {
      width: auto;
      height: 100%;

      .message {
        margin-left: 1.8rem;
        font-weight: 600;
        color: red;
      }

      .questions-btn {
        display: flex;
        height: -moz-fit-content;
        height: fit-content;
        margin: 1rem 1.25rem;
        flex-direction: row;
        justify-content: end;
        gap: 1.5rem;
        //padding: 0.5rem;

        .bn3 {
          box-sizing: border-box;
          text-decoration: none;
          background-color: #f5f5f5;
          text-align: center;
          cursor: pointer;
          padding: 0.7em 2.5em;
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: var(--font-size-small);
          line-height: 0.3125rem; /* converted from 5px or 58% */
          color: #000000;
        }
      }
      .questions-stack {
        overflow-x: hidden;
        overflow-y: auto;
        height: 65.5vh;
        margin-bottom: 1rem;

        .questions-card {
          display: flex;
          margin: 0.5rem 1.25rem;
          height: fit-content;
          padding: 0.5rem 0;
          align-items: center;
          border-bottom: 0.341815px solid #e0e0e0;

          .ques-avatar {
            display: flex;
            gap: 1rem;
            align-items: center;
            /* width: 70%; */
            margin-right: 1rem;
          }
          .questions > * {
            margin: 0.5rem 0 !important;
          }

          .tech-questions {
            width: 60%;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: var(--font-size-medium);
            line-height: 1.2rem;

            display: flex;
            align-items: center;

            color: #000000;
          }

          .msg_notifs_option_btn {
            display: flex;
            flex-direction: row;
            position: relative;
            align-items: center;
            width: -moz-fit-content;
            width: fit-content;
            height: 100%;
            margin: 0.5rem auto;
            gap: 1.5rem;

            .bn3 {
              box-sizing: border-box;
              text-decoration: none;
              background-color: #f5f5f5;
              color: #000000;
              text-align: center;
              cursor: pointer;
              padding: 0.7em 2.5em;
              font-family: "Manrope";
              font-style: normal;
              font-weight: 600;
              font-size: var(--font-size-small);
              line-height: 0.3125rem;
            }
          }
        }
      }
      .questions-stack::-webkit-scrollbar {
        width: 0.1rem; /* Set the width of the scrollbar */
      }
    }
  }
}

@media #{$media-desktop-plus} {
  .ques-center-div {
    width: 99% !important;
    max-width: 99% !important;
    height: 95vh !important;
    .ques_main {
      height: 100% !important;
    }
  }
}

@media #{$media-three-sixty} {
  .ques-center-div {
    height: 94vh !important;
    .ques_main {
      height: 100% !important;
    }
  }
}

@media #{$media-three-seventy-five} {
  .ques-center-div {
    height: 94vh !important;
    .ques_main {
      height: 100% !important;
    }
  }
}
