.text-transcription-div {
    margin-top: 10px;
    padding: 10px;
    border-radius: 15px;
    min-width: 85vw;
    max-width: 85vw;
    min-height: 12vh;
    max-height: 12vh;
    font-weight: 500 !important;
    outline: none;
    opacity: 0.8;
    background: grey;
    resize: none;
    overflow-y: scroll;
}

.text-transcription-div::placeholder {
    font-weight: 500 !important;
    color: #424242;
}

.text-transcription-div::-webkit-scrollbar {
    display: none;
}

.text-transcription-div::-webkit-scrollbar-thumb {
    display: none;
}

.question-div {
    padding: 10px;
    border-radius: 15px;
    min-width: 85vw;
    max-width: 85vw;
    opacity: 0.8;
    background: grey;
    font-weight: 600;
    font-size: 18px;
}

.video-feed-div {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    object-fit: cover;
    height: 100vh;
}

.video-answer-div {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    object-fit: cover;
    height: 100vh;
    z-index: 90 !important;
}

.overlay {
    z-index: 101 !important;
}

.bottom-controls-div {
    position: absolute;
    bottom: 1vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    min-height: 5.5rem;
    // margin-left: 85vw;
}

.video-recorder-body {
    position: absolute;
    bottom: 15vh;
    z-index: 1;
    text-align: center;
}

.close-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 18px;
    cursor: pointer;
    z-index: 1;
    background: grey;
    opacity: 0.9;
    border-radius: 30px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
}

@media (min-width: 640px) {
    .question-div {
        min-width: 600px;
        max-width: 600px;
        margin: 10px auto;
    }

    .text-transcription-div{
        min-width: 600px;
        max-width: 600px;
        margin: 10px auto 0px auto;
    }

    .close-btn {
        left: calc(50% - 320px); 
    }
}