.invite_container {
  background-color: $orangeColor;
  position: fixed;
  bottom: 4.8rem;
  width: 53vw;

  .title {
    color: $whiteColor;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 1rem 1.3rem 0 1.2rem;
  }

  .input_container {
    @include flexRow;
    justify-content: center;

    input {
      width: 70%;
      border: none;
      outline: none;
      margin-right: 0.25rem;
      // border-radius: 0.25rem;
      padding: 0.5rem;
      color: $connTextColor;
    }

    button {
      color: $whiteColor;
      background-color: $blackColor;
      font-size: 1rem;
      font-weight: 700;
      padding: 1rem 2.5rem;
      border: none;
      // border-radius: 0.25rem;
      margin-left: 0.25rem;
    }
  }

  .dismiss_container {
    @include flexRow;
    justify-content: space-between;
    color: $whiteColor;
    font-size: 1rem;
    font-weight: 700;
    padding: 0 1.3rem 0 1.2rem;

    .dismiss {
      cursor: pointer;
    }
  }
}

@media #{$media-desktop-plus} {
  .invite_container {
    width: 100%;
    bottom: 0;
  }
}

@media #{$media-nine-twelve}, #{$media-eight-twenty} {
  .invite_container {
    width: 40vw;

    .input_container {
      input {
        width: 64%;
      }
    }
  }
}

@media #{$media-seven-sixty-eight} {
  .invite_container {
    .title {
      padding: 1rem 6.7rem 0 6.5rem
    }

    .dismiss_container {
      padding: 0 6.7rem 0 6.5rem;;
    }
  }
}

@media #{$media-five-fourty} {
  .invite_container {
    .title {
      padding: 1rem 3.5rem 0 3.3rem
    }

    .dismiss_container {
      padding: 0 3.5rem 0 3.3rem;;
    }
  }
}

@media  #{$media-two-eighty} {
  .invite_container {
    .input_container {
      input {
        width: 57%;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1040px) {
  .invite_container {
    width: 53vw;
  }
}
