.error_msg_container {
  position: absolute;
  right: 30%;
  left: 28%;
  top: 50%;
  background-color: $whiteColor;
  color: $darkGreyColor;
  min-width: 20rem;
  min-height: 4rem;
  padding: 1rem;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 14%), 
            0px 3px 1px -2px rgb(0 0 0 / 12%),
            0px 1px 5px 0px rgb(0 0 0 / 20%);
  z-index: 99;

  .close_err_msg_modal {
    text-align: right;
    color: $orangeColor;
    font-weight: 600;
    cursor: pointer;
  }

  .err_msg {
    font-size: 1em;
    font-weight: 500;
  }

  div {
    width: 1.3rem;
    padding: 0.4rem 1rem;
    margin: auto;
    border: none;
    background-color: $blackColor;
    font-size: 1rem;
    text-decoration: none;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    color: $whiteColor;
    text-shadow: 0 0.04rem 0.04rem rgb(255 255 255 / 25%);
    transition: all 0.2s;
    cursor: pointer;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  border: 1px solid $orangeColor;
}

@media #{$media-desktop-plus} {
  .error_msg_container {
    left: 20%;
    right: 20%;
    top: 30%;
  }
}
