.register_container {
  overflow-y: hidden !important;
  // width: 53vw;
  // max-width: 53vw;
  height: 100dvh;
  // @include flexColumn;
  display: flex;
  justify-content: center;
  // background-color: $whiteColor;
  // position: fixed;
  // bottom: 7.5rem;
  // right: 1.8rem;
  font-family: duo-round, sans-serif;
  // border-radius: 1%;
  background: linear-gradient(146.18deg, #00c1aa 0.99%, $oratioBlue2 100%);
  // box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
  //   0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
  //   0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);

  .register_contents {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 1.5rem 1.75rem;

    .logo_container {
      margin: 0 auto;
      height: 80px;
      width: 80px;

      img {
        width: 4.375rem;
        height: 4.375rem;
        margin: 1.1rem 0 -2.188rem 0;
      }
    }

    h2 {
      text-align: center;
    }

    .input_container,
    .error_container {
      @include flexColumn;
      width: 90%;

      label {
        font-size: 19px;
        font-weight: 700;
        color: #ffffff;
        opacity: 0.85;
        margin-bottom: 0.3rem;
      }

      input {
        height: 2.188rem;
        padding: 0.188rem;
        font-size: 1rem;
        border-radius: 0.313rem;
        outline: none;
        border: 1px solid rgb(204, 204, 204);
        padding: 0.313rem 0.625rem;
      }
    }

    .input_container {
      margin: 1.25rem auto;
    }

    .error_container {
      margin-left: 1rem;
    }

    .spryte {
      font-size: 2.125rem;
      font-weight: 800;
      text-align: center;
      color: $blackColor;
      margin: 0;

      span {
        opacity: 0.65;
      }
    }

    h2 {
      font-size: 24px;
      text-align: center;
      font-weight: 800;
      color: #ffffff;
      margin-top: 14px;
    }

    .agreement_container {
      @include flexRow;
      margin-top: 1rem;

      input {
        width: fit-content !important;
        margin-right: 0.3rem;
        margin-top: 0.15rem;
      }

      input[type="checkbox"] {
        accent-color: #fff;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        /* letter-spacing: -0.5px; */
        width: 100%;
        line-height: 1.4rem;
      }
    }

    .button_container {
      @include flexRow;
      margin: 1.75rem auto 1rem auto;
      text-align: center;

      .back_btn {
        width: 12.5%;
        margin-right: 0.3rem;
        height: 2.6rem;
        background-color: #000102;
        border-radius: 0.15rem;
        @include flexRow;
        // align-items: center;
        justify-content: center;
        cursor: pointer;

        h3 {
          color: white;
          font-size: 18px;
        }
      }

      button {
        color: #ffffff;
        background: #4cc4eb;
        border-radius: 5%;
        border: none;
        padding: 0.5rem 0.7rem;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0 2px 0 #4cc4eb;
        width: 90%;
      }

      .step1_btn {
        width: 100%;
      }

      .step2_btn,
      .step3_btn {
        width: 100%;
      }
    }

    .error::before {
      content: "\26A0";
      font-size: 18px;
      margin-right: 5px;
      vertical-align: middle;
    }

    .error {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.25px;
      color: #ffffff;
      padding: 0.275rem;
      border: 2px solid #d9534f;
      border-radius: 8px;
      background-color: #d9534f;
      margin: 1rem 0;
      display: inline-block;
    }

    .sign_in {
      text-align: center;
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.5rem;

      span {
        color: #fff;
        font-weight: 800;
        font-size: 18.5px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-height: 360px) and (max-height: 760px) {
  .register_container {
    padding: auto;

    .register_contents {
      overflow-y: visible;

      .logo_container {
        height: 50px;
        width: 80px;
        margin: auto;
        margin-bottom: 40px;
      }
    }

    .button_container {
      margin: 1rem auto 1rem auto;
    }
  }
}
