@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

.mutual-connection__image,
.avatar__image,
.avatar__thumbnail:after {
  border-radius: 50%;
}

body{
  background-color: rgb(211, 211, 211);
}

.ce-my-message-bubble {
  background-color: #7a39e0 !important;
}

.ce-chat-title-text {
  color: #7a39e0 !important;
}

.ce-chat-subtitle-text {
  color: #7a39e0 !important;
  font-weight: 600;
}

#ce-send-message-button {
  position: relative !important;
  bottom: 0.125rem !important;
  left: 0.063rem !important;
  background-color: #7a39e0 !important;
}

.ce-my-message-timestamp {
  color: #7a39e0 !important;
}

.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}


.transition-6 {
  transition: all 2s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.loggedIn_avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: fixed;
  bottom: 0.75rem;
  right: 2.5rem;
  overflow: hidden;
  cursor: pointer;
}

.loggedIn_avatar img {
  width: 100%;
  height: 100%;
}

.loggedIn_status {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  position: fixed;
  bottom: 3.6rem;
  right: 3rem;
  overflow: hidden;
  background-color: #07CF1B;
  z-index: 99;
  cursor: pointer;
}

.status_modal {
  position: relative;
  left: 36rem;
  bottom: 11rem;
}
/* .curr_user_avatar {
  border: 0.25rem solid #7a39e0;
} */

@media only screen and  (max-width: 1040px) {
  .loggedIn_avatar {
    display: none;
  }

  .status_modal {
    display: none;
  }

  .loggedIn_status {
    display: none;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1040px) {
  .loggedIn_avatar {
    display: none;
  }

  .status_modal {
    display: none;
  }

  .loggedIn_status {
    display: none;
  }
} */

@media only screen and (max-width: 1300px){
  .status_modal {
    left: 34rem;
  }
}

@media only screen and (max-width: 1250px){
  .status_modal {
    left: 32rem;
  }
}

@media only screen and (max-width: 1125px){
  .status_modal {
    left: 28rem;
  }

  .status_modal {
    display: none;
  }
}

/* @media only screen and (width: 820px) {
  .loggedIn_avatar {
    display: none;
  }

  .loggedIn_status {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .loggedIn_avatar {
    display: none;
  }

  .loggedIn_status {
    display: none;
  }
} */
