@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.conn-center-div {
  @include flexColumn;
  width: 53vw;
  max-width: 53vw;
  height: 80vh;
  font-family: 'Manrope', sans-serif;

  .connection_main {
    background-color: rgb(255, 255, 255);
    // overflow-y: auto;
    // overflow-x: hidden;
    border-radius: 1%;
    // height: 100%;

    .categories_bar,
    .mobile_categories_bar {
      display: flex;
      flex-direction: row;
      margin: 0.313rem auto 0.313rem auto;
      // border-bottom: 0.063rem solid #c0c0c0;
  
      .bn1 {
        height: 1.875rem;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.5rem 1.15rem 1.75rem 1.15rem;
        margin: 0.313rem auto 0 auto;
        background-color: $whiteColor;
        // border-radius: 2rem;
        font-size: 0.875rem;
        font-weight: 400;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        color: $catsColor;
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
      
        &:hover {
          background-color: $greyColor;
          color: $blackColor;
          transform:scale(1.03,1.03);
        }
      }

      .categories_item.clicked {
        color: $blackColor;
        background-color: $greyColor;
      }      

      .none {
        display: none;
        background-color: red;
      }
    }

    .categories_bar {
      width: 95%;
      // padding-bottom: 0.938rem;
    }

    .mobile_categories_bar {
      display: none;
    }

    @media #{$media-desktop-plus2} {
      .categories_bar {
        flex-wrap: wrap;
      }
    }

    @media #{$media-desktop-plus} {
      .categories_bar {
        // display: none;
        justify-content: space-between;

        .bn1 {
          margin: 0 0.313rem;
        }
      }

      .mobile_categories_bar {
        display: block;
        width: 92%;
        border-bottom: none;
        // padding-bottom: -2rem !important;
  
        .prev_container,
        .next_container {
          max-width: 2rem;
          max-height: 2.1rem;
          // position: relative;
          position: absolute;
          background-color: #afe4f7;
          border-radius: 50%;
          cursor: pointer;
        }
  
        .prev_container {
          top:10.8rem;
          left: 1.1rem;
        }
  
        .next_container {
          top:10.8rem;
          right: 1rem;
        }

        @media only screen and (max-width: 768px) {
          .prev_container {
            top: 7.4rem;
            left: 2rem;
          }
    
          .next_container {
            top: 7.4rem;
            right: 1.8rem;
          }
        }

        @media only screen and (max-width: 540px) {
          .prev_container {
            left: 1.1rem;
          }
    
          .next_container {
            right: 1rem;
          }
        }

        @media only screen and (width: 540px) {
          .prev_container {
            left: 1.5rem;
          }
    
          .next_container {
            right: 1.4rem;
          }
        }
      }
    }
  }
}

@media #{$media-tablet} {
  .conn-center-div {
    width: 100vw;
    max-width: 100vw;
    height: 95vh;

    .connection_main {
      height: 100%;
    }
  }
}
