/**
 * Reset all resets shoul be here
 */

html {
  color: #222;
  font-size: 62.5%;
  line-height: 1.4;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0 !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none !important;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}
