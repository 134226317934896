.search_box_container,
.new_group_search_box_container {
  // width: 95%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0.625rem auto;

  input {
    color: $blackColor;
    width: 100%;
    padding: 0.75rem 0.625rem;
    outline: 0;
    font-size: 0.85rem;
    font-weight: 550;
    height: 50%;
    border: 0.063rem solid $borderColor;
    // adds search icon as a bg image to the search input element
    background-image: url('/static/search-icon.png');
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.3rem 1.3rem;
    line-height: 1.5;

    // hides saerch icon (which a bg image) when the input element is not empty
    &:not(:placeholder-shown) {
      background-image: none;
      padding-right: 10px;
    }
  }

  input::-webkit-input-placeholder { 
    font-size: 0.875rem;
    font-weight: normal;
    color: $darkGreyColor;
  }

  .search_dropdown {
    position: absolute;
    // top: 10.2rem;
    background-color: $whiteColor;
    border-top: 1px grey solid;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 0.938rem;
    border-radius: 0.625rem;
    overflow-y: auto;
    // min-width: 95%;
    // max-width: 95%;
    // max-height: 43.75rem;
    z-index: 2;

    .search_results {
      font-size: small;
      @include flexRow;
      border-bottom: 1px solid rgba(216, 216, 216, 0.767) ;
      background-color: $whiteColor;
      padding: 1.25rem;

      &:hover {
        background-color: $greyHover;
      }

      .search_results_avatar {
        margin-right: 0.625rem;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
      }

      .search_result_data {
        display: flex;
        margin-top: 0.313rem;

        p {
          padding: 0%; 
          margin: 0% ;
          font-size: 0.75rem;
          color:  $tintedBlackColor;

          .dots {
            height: 0.5rem;
            width: 0.5rem;
            background-color: $blackColor;
            border-radius: 50%;
            display: inline-block;
            margin-right: 0.625rem;
            margin-left: 0.625rem;
          }
        }
      }

      .search_connect_btn,
      .search_message_btn,
      .search_pending_btn,
      .accept_request_btn {
        width: 5.625rem;
        height: 1.875rem;
        border: 0.16rem solid rgb(224, 224, 233);
        background-color: $blackColor;
        font-size: 0.85rem;
        box-sizing: border-box;
        text-decoration: none;
        font-family: 'Manrope', sans-serif;
        font-weight: 600;
        color: $whiteColor;
        text-shadow: 0 0.04rem 0.04rem rgb(255 255 255 / 25%);
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          color: $blackColor;
          background-color: rgb(255, 255, 255);
          transform:scale(1.03,1.03);
        }
      }

      .search_pending_btn {
        margin: 0 0 0 auto;
        pointer-events: none;
        padding: 0.25rem 0.5rem;
      }

      .search_connect_btn,
      .search_message_btn,
      .accept_request_btn {
        margin: 0 0 0 auto;
        padding: 0.15rem 0.5rem;
      }
    }
  }

  .no_data_found {
    position: absolute;
    // top: 6.2rem;
    background-color: $whiteColor;
    padding: 0 1.25rem;
    font-size: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 0.938rem;
    z-index: 2;
  }
}

// styles specific to searchbix on ConnectionWindow comp
.search_box_container {
  width: 95%;

  .search_dropdown {
    top: 10.2rem;
    max-height: 43.75rem;
    min-width: 95%;
    max-width: 95%;
  }

  .no_data_found {
    top: 6.8rem;
  }
}

// styles specific to searchbox on NewGroupModal comp
.new_group_search_box_container {
  width: 100%;

  .search_dropdown {
    top: 19.5rem;
    max-height: 25rem;
    min-width: 98%;
    max-width: 98%;
  }

  .no_data_found {
    top: 18.9rem;
  }
}

@media #{$media-tablet} {
  .search_box_container {
    .search_dropdown {
      top: 6.7rem;
    }
  }
}

@media screen and (min-width: 1041px) {
  .search_box_container {
    .search_dropdown {
      top: 6.7rem;
    }
  }
}
